import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import AuthRoutes from './auth'
import CustomerRoutes from './customers'
import VendorRoutes from './vendors'
import store from '@/store'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/power/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        {
          path: '/power/dashboard',
          meta: { title: 'Dashboard' },
          component: () => import('../views/power/dashboard'),
        },
        {
          path: '/sales',
          meta: { title: 'Sales' },
          redirect: { name: 'customers' },
          component: () => import('../views/sales'),
        },
        ...CustomerRoutes,
        {
          path: '/sales/consignments',
          meta: { title: 'Consignments' },
          component: () => import('../views/sales/consignments'),
        },
        {
          path: '/sales/consignments/entry',
          meta: { title: 'Shipment Entry' },
          name: 'consignmentEntry',
          component: () => import('../views/sales/consignments/entry/quickEntry.vue'),
        },
        {
          path: '/sales/consignments/:id/edit',
          meta: { title: 'Edit Shipment' },
          name: 'consignmentEdit',
          component: () => import('../views/sales/consignments/entry/quickEntry.vue'),
        },
        {
          path: '/sales/invoices',
          meta: { title: 'Invoices' },
          name: 'invoices',
          component: () => import('../views/sales/invoices'),
        },
        {
          path: '/sales/invoices/new',
          meta: { title: 'New Invoice' },
          name: 'newInvoice',
          component: () => import('../views/sales/invoices/new'),
        },
        {
          path: '/sales/creditnotes',
          meta: { title: 'Credit Notes' },
          component: () => import('../views/sales/creditnotes'),
        },
        {
          path: '/sales/paymentsreceived',
          meta: { title: 'Payments Received' },
          component: () => import('../views/sales/paymentsreceived'),
        },
        {
          path: '/sales/estimates',
          meta: { title: 'Estimates' },
          component: () => import('../views/sales/estimates'),
        },
        {
          path: '/purchase',
          meta: { title: 'Purchase' },
          component: () => import('../views/purchase'),
        },
        ...VendorRoutes,
        {
          path: '/purchase/addons',
          meta: { title: 'Add Ons' },
          component: () => import('../views/purchase/addons'),
        },
        {
          path: '/purchase/expenses',
          meta: { title: 'Expenses' },
          component: () => import('../views/purchase/expenses'),
        },
        {
          path: '/purchase/bills',
          meta: { title: 'Bills' },
          component: () => import('../views/purchase/bills'),
        },
        {
          path: '/purchase/vendorcredits',
          meta: { title: 'Vendor Credits' },
          component: () => import('../views/purchase/vendorcredits'),
        },
        {
          path: '/shipping',
          meta: { title: 'Shipping' },
          component: () => import('../views/shipping'),
        },
        {
          path: '/shipping/zones',
          meta: { title: 'Zones' },
          component: () => import('../views/shipping/zones'),
        },
        {
          path: '/shipping/rates',
          meta: { title: 'Rates' },
          component: () => import('../views/shipping/rates'),
        },
        {
          path: '/shipping/track',
          meta: { title: 'Shipment Tracking' },
          component: () => import('../views/shipping/track'),
        },
        {
          path: '/shipping/locations',
          meta: { title: 'Locations' },
          component: () => import('../views/shipping/locations'),
        },
        {
          path: '/management',
          meta: { title: 'Management' },
          component: () => import('../views/management'),
        },
        {
          path: '/management/companies',
          meta: { title: 'Companies' },
          component: () => import('../views/management/companies'),
        },
        {
          path: '/management/companies/new',
          meta: { title: 'New Company' },
          name: 'newCompany',
          component: () => import('../views/management/companies/new'),
        },
        {
          path: '/management/taxes',
          meta: { title: 'Taxes' },
          component: () => import('../views/management/taxes'),
        },
        {
          path: '/management/usersroles',
          meta: { title: 'Users & Roles' },
          component: () => import('../views/management/usersroles'),
        },
        {
          path: '/management/employees',
          meta: { title: 'Employees' },
          component: () => import('../views/management/employees'),
        },
        {
          path: '/tools/importexport',
          meta: { title: 'Import/Export' },
          name: 'importFile',
          component: () => import('../views/tools/importexport'),
        },
        {
          path: '/tools/reports',
          meta: { title: 'Reports' },
          component: () => import('../views/tools/reports'),
        },
        {
          path: '/users/profile/edit',
          name: 'userProfile',
          meta: { title: 'User Profile' },
          component: () => import('../views/users/profile/edit'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [...AuthRoutes],
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)

  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
