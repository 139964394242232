import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import { ConsignmentImportData } from '@/types/appcontracts/ConsignmentImportData'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import { gstRegex } from './constants'

dayjs.extend(relativeTime)

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const GetAntColumnsFromArray = (columnsKeys: Array<string>) => {
  const cols = columnsKeys.map((x) => {
    return {
      title: startCase(camelCase(x)),
      dataIndex: x,
      key: x,
    }
  })
  return cols
}

export const GetDateFromNow = (date?: string | Date) => {
  return dayjs(date).fromNow()
}

export const GetShortDateFormat = (date?: string | Date) => {
  return dayjs(date).format('ll')
}

export const GetTimeFromDate = (date?: string | Date) => {
  return dayjs(date).format('LT')
}

export const getFormattedDate = (date: any, format: string | null = null) => {
  return dayjs(date).format(format || 'DD/MM/YYYY')
}

export const getDayEndDate = (date?: string | Date) => {
  if (!date) {
    return null
  }
  return dayjs(date)
    .add(1, 'day')
    .subtract(1, 'minute')
    .toDate()
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function getObjectDifference(object: Object, base: Object): object {
  function changes(object: Object, base: Object) {
    return transform(object, function(result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

// Replaces :params with actual values.
// Ex: /sales/customers/:id/profile => m=/:id, g1=/, g2=id
// TODO: Move to a common place.
export const formatStringParam = (str: string, params: Record<string, string>) => {
  if (str && params) {
    return str.replace(/(^|\/):(\w+)(?=\/|$)/g, (m, g1, g2) => g1 + (params[g2] || m))
  }
  return null
}

export const isStringEqual = (
  strA: string,
  strB: string,
  keepSingleWhiteSpace: Boolean = true,
  ignoreCase: Boolean = true,
) => {
  strA = keepSingleWhiteSpace ? strA.trim().replace(/\s\s+/g, ' ') : strA.trim().replace(/\s/g, '')
  strB = keepSingleWhiteSpace ? strB.trim().replace(/\s\s+/g, ' ') : strB.trim().replace(/\s/g, '')
  if (ignoreCase) {
    return strA.toLowerCase() === strB.toLowerCase()
  }
  return strA === strB
}

/**
 * String compare.
 * @param {NullableString} aliasA
 * @param {NullableString} aliasB
 * @return {boolean}
 */
export const isAliasEqual = (aliasA: NullableString, aliasB: NullableString): boolean => {
  if (!aliasA || !aliasB) {
    return false
  }
  return isStringEqual(aliasA, aliasB, false, true)
}

export const formatAddressObject = (
  addressObj: IAddressFormat,
  isAttention: boolean = false,
): string => {
  const fields: (NullableString | undefined)[][] = [
    [addressObj?.address],
    [addressObj?.city, addressObj?.state, addressObj?.pincode, addressObj?.country],
  ]
  if (isAttention) {
    fields.unshift([addressObj?.attention])
  }
  return fields
    .map(function(part) {
      return part.filter(Boolean).join(', ')
    })
    .filter(function(str) {
      return str.length
    })
    .join('\n')
}

/**
 * Format Address object for display
 * @param {IAddressFormat} addressObj
 * @param {boolean} isAttention
 * @return {string}
 */
export const formatDestObject = (addressObj: IAddressFormat | null): string => {
  const fields = [
    addressObj?.city,
    addressObj?.pincode,
    addressObj?.state,
    addressObj?.country,
  ].filter((s) => s !== null && s?.length)

  // Take first 2 in order.
  if (fields.length >= 2) {
    return `${fields[0]}, ${fields[1]}`
  } else if (fields.length >= 1) {
    return `${fields[0]}`
  } else {
    return ''
  }
}

export const convertConsignmentImportDataToNumeric = (data: ConsignmentImportData): void => {
  // If value is null/undefine then set as null, if it gives NaN, set as 0
  if (data.weight !== undefined) {
    data.weight = isNil(data.weight) ? null : parseFloat(data.weight?.toString()) || 0
  }
  if (data.dimensionsLength !== undefined) {
    data.dimensionsLength = isNil(data.dimensionsLength)
      ? null
      : parseFloat(data.dimensionsLength?.toString()) || 0
  }
  if (data.dimensionsBreadth !== undefined) {
    data.dimensionsBreadth = isNil(data.dimensionsBreadth)
      ? null
      : parseFloat(data.dimensionsBreadth?.toString()) || 0
  }
  if (data.dimensionsHeight !== undefined) {
    data.dimensionsHeight = isNil(data.dimensionsHeight)
      ? null
      : parseFloat(data.dimensionsHeight?.toString()) || 0
  }
  if (data.pcs !== undefined) {
    data.pcs = isNil(data.pcs) ? null : parseFloat(data.pcs?.toString()) || 0
  }
  if (data.amount !== undefined) {
    data.amount = isNil(data.amount) ? null : parseFloat(data.amount?.toString()) || 0
  }
  if (data.shipmentValue !== undefined) {
    data.shipmentValue = isNil(data.shipmentValue)
      ? null
      : parseFloat(data.shipmentValue?.toString()) || 0
  }
  if (data.vchc !== undefined) {
    data.vchc = isNil(data.vchc) ? null : parseFloat(data.vchc?.toString()) || 0
  }
}

export const formatToINR = (number: number) => {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(number)
}

export const getPercent = (num: number, den: number) => {
  if (num && den && den !== 0) {
    return (num / den) * 100
  }
  return 0
}

export const getValueOfPercentage = (num: number, per: number) => {
  return fixDecimalValue((num / 100) * per)
  // return parseFloat(((num / 100) * per).toFixed(2))
}

/**
 * Fix decimal paces for a number.
 * @param {number} num
 * @param {number} decimalPlaces
 * @return {number}
 */
export const fixDecimalValue = (num: number, decimalPlaces: number = 2): number => {
  return parseFloat(num.toFixed(decimalPlaces))
}

export const downloadBase64File = (data: string, type: string, fileName: string) => {
  var a = document.createElement('a')
  a.href = `data:${type};base64,${data}`
  a.download = `${fileName}`
  document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click()
  a.remove() //afterwards we remove the element again
}

export const isCurrentFy = (month: number, year: number) => {
  const currYear = new Date().getFullYear()
  const currMonth = new Date().getMonth()
  const arr = [currYear, currYear]
  if (currMonth < 3) {
    arr[0] = currYear - 1
  } else {
    arr[1] = currYear + 1
  }
  if ((month < 3 && year === arr[1]) || (month > 3 && year === arr[0])) {
    return true
  }
  return false
}

function getWholeNumberInWords(amount: any) {
  var words = new Array()
  words[0] = 'Zero'
  words[1] = 'One'
  words[2] = 'Two'
  words[3] = 'Three'
  words[4] = 'Four'
  words[5] = 'Five'
  words[6] = 'Six'
  words[7] = 'Seven'
  words[8] = 'Eight'
  words[9] = 'Nine'
  words[10] = 'Ten'
  words[11] = 'Eleven'
  words[12] = 'Twelve'
  words[13] = 'Thirteen'
  words[14] = 'Fourteen'
  words[15] = 'Fifteen'
  words[16] = 'Sixteen'
  words[17] = 'Seventeen'
  words[18] = 'Eighteen'
  words[19] = 'Nineteen'
  words[20] = 'Twenty'
  words[30] = 'Thirty'
  words[40] = 'Forty'
  words[50] = 'Fifty'
  words[60] = 'Sixty'
  words[70] = 'Seventy'
  words[80] = 'Eighty'
  words[90] = 'Ninety'
  amount = amount.toString()
  var atemp = amount.split('.')
  var number = atemp[0].split(',').join('')
  var n_length = number.length
  var words_string = ''
  if (n_length <= 9) {
    var n_array = new Array<any>(0, 0, 0, 0, 0, 0, 0, 0, 0)
    var received_n_array = new Array()
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1)
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j]
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j])
          n_array[i] = 0
        }
      }
    }
    var value: any = ''
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10
      } else {
        value = n_array[i]
      }
      if (value != 0) {
        words_string += words[value] + ' '
      }
      if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
        words_string += 'Crores '
      }
      if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
        words_string += 'Lakhs '
      }
      if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
        words_string += 'Thousand '
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += 'Hundred '
      } else if (i == 6 && value != 0) {
        words_string += 'Hundred '
      }
    }
    words_string = words_string.split(' ').join(' ')
  }
  return words_string
}

export function getAmountInWords(n: any) {
  var nums: any = n.toString().split('.')
  var whole = getWholeNumberInWords(nums[0])
  if (nums[1] == null) nums[1] = 0
  if (nums[1].length == 1) nums[1] = nums[1] + '0'
  if (nums[1].length > 2) {
    nums[1] = nums[1].substring(2, length - 1)
  }
  if (nums.length == 2) {
    if (nums[0] <= 9) {
      nums[0] = nums[0] * 10
    }
    // else {
    //   nums[0] = nums[0]
    // }
    var op: any = ''
    var fraction = getWholeNumberInWords(nums[1])
    if (whole == '' && fraction == '') {
      op = 'Zero Only'
    }
    if (whole == '' && fraction != '') {
      op = fraction + 'Paise Only'
    }
    if (whole != '' && fraction == '') {
      op = 'Indian Rupee ' + whole + ' Only'
    }
    if (whole != '' && fraction != '') {
      op = 'Indian Rupee ' + whole + 'and ' + fraction + 'Paise Only'
    }
    return op
  }
}

export function isGstValid(gstin: string | null) {
  if (gstin) {
    return gstRegex.test(gstin)
  }
  return false
}
