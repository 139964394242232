import * as fb from '../../../services/firebase/modules/vendors'
import { notifySuccess, notifyWarning } from '../../helpers'
import { VendorsAction } from '../../actions.type'
import { VendorsMutation } from '@/store/mutations.type'
import { DocumentActions } from '@/types/enums/DocumentActions'

const state = () => ({
  vendorsIdMap: new Map(),
  vendorsLoading: true,
  unsubscribeListenersList: [],
  fetchSize: 15,
  vendorsSearchList: [],
  lastDocRef: null,
})

const getters = {
  vendorsExists: (state) => state.vendorsIdMap.size != 0,
  vendorList: (state) => {
    if (state.vendorsIdMap) {
      return [...state.vendorsIdMap.values()]
    } else {
      return []
    }
  },
  vendorsAliasList: (state) => state.vendorsSearchList?.map((x) => x.alias),
}

const mutations = {
  [VendorsMutation.SET_VENDORS_LOADING](state, payload) {
    state.vendorsLoading = payload
  },

  [VendorsMutation.SET_VENDORS_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.vendorsIdMap = payload ? new Map([...state.vendorsIdMap, ...payload]) : new Map()
  },

  [VendorsMutation.SET_VENDORS_SEARCH_LIST](state, payload) {
    state.vendorsSearchList = payload || []
  },

  [VendorsMutation.SET_VENDORS_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [VendorsMutation.REMOVE_VENDOR](state, payload) {
    if (state.vendorsIdMap?.has(payload)) {
      state.vendorsIdMap?.delete(payload)
    }
  },

  [VendorsMutation.SET_VENDORS_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [VendorsAction.SET_OR_DELETE_VENDOR](context, payload) {
    // TODO: Check payload if op is create
    try {
      const resp = await fb.setOrDeleteVendor(payload)
      if (resp.result.isSuccess) {
        // await context.dispatch(VendorsAction.FETCH_VENDORS_BY_IDLIST, {
        //   idList: [resp.responseData?.id],
        // })
        if (payload.action !== DocumentActions.DELETE) {
          notifySuccess(
            `Vendor ${payload?.action?.toLowerCase()} successful`,
            `Vendor ID is ${resp.responseData?.cid}`,
          )
        } else {
          context.commit(VendorsMutation.REMOVE_VENDOR, payload.id)
          notifySuccess('Vendor Deleted.')
        }
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  // async [VendorsAction.UPDATE_VENDOR](context, payload) {
  //   try {
  //     const resp = await fb.setOrDeleteVendor(payload)
  //     if (resp.result.isSuccess) {
  //       notifySuccess(`Updated Vendor`, `Vendor ID is ${resp.responseData?.vid}`)
  //     }
  //   } catch (error) {
  //     notifyWarning(error)
  //   }
  // },

  async [VendorsAction.FETCH_VENDORS_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchVendorsFromServer(payload.fetchSize, context.state.lastDocRef)
      context.commit(VendorsMutation.SET_VENDORS_MAP, resp.data)
      context.commit(VendorsMutation.SET_VENDORS_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [VendorsAction.FETCH_VENDORS_ON_LOAD](context) {
    try {
      context.commit(VendorsMutation.SET_VENDORS_LOADING, true)

      // Making vendors map null on refresh, otherwise latest wont show up on top.
      context.commit(VendorsMutation.SET_VENDORS_LAST_DOCREF, null)
      context.commit(VendorsMutation.SET_VENDORS_MAP, null)

      await context.dispatch(VendorsAction.FETCH_VENDORS_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
      })
    } catch (error) {
      notifyWarning(error, true)
    } finally {
      context.commit(VendorsMutation.SET_VENDORS_LOADING, false)
    }
  },

  async [VendorsAction.LOAD_VENDORS_ALIAS_BY_LISTENERS](context) {
    try {
      const unsubscribe = await fb.setupVendorsAliasListener(
        (vendorsSearchDoc) => {
          context.commit(VendorsMutation.SET_VENDORS_LOADING, true)
          context.commit(
            VendorsMutation.SET_VENDORS_SEARCH_LIST,
            vendorsSearchDoc.doc?.vendorMapping,
          )
          context.commit(VendorsMutation.SET_VENDORS_LOADING, false)
        },
        () => {
          context.commit(VendorsMutation.SET_VENDORS_LOADING, false)
        },
      )
      context.commit(VendorsMutation.SET_VENDORS_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      notifyWarning(error, true)
    }
  },

  async [VendorsAction.UNSUBSCRIBE_VENDORS_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },

  async [VendorsAction.FETCH_VENDOR_PROFILE](context, payload) {
    try {
      if (!payload.id) {
        return null
      }
      const resp = await fb.fetchVendorProfile(payload.id)
      return resp
    } catch (error) {
      notifyWarning(error, true)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
