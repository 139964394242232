import { CountryStateDataFetch } from '@/types/appcontracts/CountryStateDataFetch'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { ICountryStateResponse } from '@/types/interfaces/ICountryStateResponse'
import * as common from '../../common'

export async function fetchCountryStateData(data: CountryStateDataFetch) {
  try {
    const resp = await common.callFunction<ICountryStateResponse>(
      FirebaseFunctions.GetCountryStateData,
      data,
    )
    return resp
  } catch {
    return {}
  }
}
