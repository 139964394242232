<template>
  <styleLoader />
  <localization />
</template>

<script>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import qs from 'qs'
import Localization from '@/localization'
import StyleLoader from '@/styleLoader'
import * as actions from './store/actions.type'
import { clarityInitialization } from './clarityInitialization'

export default {
  name: 'App',
  components: { Localization, StyleLoader },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const logo = computed(() => store.getters.settings.logo)
    const settings = computed(() => store.getters.settings)
    const routeTitle = computed(() => route.meta.title)
    const currentRoute = computed(() => route)
    const authorized = computed(() => store.getters['user/user'].authorized)

    // watch page title change
    watch(
      [logo, routeTitle],
      ([logo, routeTitle]) => (document.title = `${logo} | ${routeTitle}` || `${logo}`),
    )

    // OnCreate try to load curr user
    store.dispatch(`user/${actions.UsersAction.LOAD_CURRENT_USER_ACTION}`)

    // initial auth check
    onMounted(() => {
      // store.dispatch(`user/${actions.LOAD_CURRENT_USER_ACTION}`)
      store.commit('SET_THEME', { theme: settings.value.theme })
      clarityInitialization(process.env.NODE_ENV === 'production' ? 'b7ijxuy460' : 'b7irkecme7')
    })

    // redirect if authorized and current page is login
    watch(authorized, (authorized) => {
      if (authorized) {
        const query = qs.parse(currentRoute.value.fullPath.split('?')[1], {
          ignoreQueryPrefix: true,
        })
        router.push(query.redirect || '/')
      } else {
        router.replace({ name: 'login' })
      }
    })
  },
}
</script>
