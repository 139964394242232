import router from '../../../router'
import { notification } from 'ant-design-vue'
import localStorage from 'store'

import * as fb from '../../../services/firebase/modules/users'
import { UsersAction, GlobalAction } from '../../actions.type'
import { UsersMutation } from '../../mutations.type'
import { notifySuccess, notifyWarning } from '../../helpers'

const state = () => ({
  id: '',
  name: '',
  role: '',
  email: '',
  photoUrl: '',
  authorized: false,
  loading: false,
})

const getters = {
  user: (state) => state,
}

const mutations = {
  [UsersMutation.SET_USER_LOADING](state, loading) {
    state.loading = loading
  },

  [UsersMutation.SET_USER_PROPERTIES](state, userObject) {
    Object.assign(state, { ...userObject })
  },

  [UsersMutation.SET_USER_AUTH](state, isAuthorized) {
    state.authorized = isAuthorized
  },
}

const actions = {
  async [UsersAction.LOGIN_USER_ACTION](context, { payload }) {
    const { email, password } = payload

    // User properties shall be set by callback sent to auth listener.
    context.commit(UsersMutation.SET_USER_LOADING, true)
    const result = await fb.login(email, password)
    context.commit(UsersMutation.SET_USER_LOADING, false)

    if (result.success) {
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in!',
      })
    } else {
      notification.warning({
        message: result.error?.code ?? 'Login Error',
        description: result.error?.message ?? 'Please try again later.',
      })
    }
  },

  async [UsersAction.REGISTER_USER_ACTION]({ commit }, { payload }) {
    const { email, password, name } = payload

    commit(UsersMutation.SET_USER_LOADING, true)
    const result = await fb.register(email, password, name)
    commit(UsersMutation.SET_USER_LOADING, false)

    if (result.success) {
      notification.success({
        message: 'Congrats',
        description: 'Your registration was successful.',
      })
      router.push('/auth/login')
    } else {
      notification.warning({
        message: result.error?.code ?? 'Registration Error',
        description: result.error?.message ?? 'Please try again later.',
      })
    }
  },

  async [UsersAction.LOAD_CURRENT_USER_ACTION](context) {
    context.commit(UsersMutation.SET_USER_LOADING, true)
    context.commit(UsersMutation.SET_USER_AUTH, GetUserAuthFromLocalStorage())

    await fb.setupAuthListener(async (user) => {
      // Auth is set in the user object by auth listener based on role
      if (!user.authorized) {
        localStorage.set('app.user.authorized', false)
        context.dispatch(`appGlobals/${GlobalAction.UNSUBSCRIBE_GLOBAL_LISTENERS}`, null, {
          root: true,
        })

        // TODO: Check if logout should be called always or not
        await fb.logout()
      } else {
        localStorage.set('app.user.authorized', true)
        context.dispatch(`appGlobals/${GlobalAction.SETUP_GLOBAL_LISTENERS}`, null, { root: true })
      }
      context.commit(UsersMutation.SET_USER_PROPERTIES, { ...user })
    })

    context.commit(UsersMutation.SET_USER_LOADING, false)
  },

  async [UsersAction.LOGOUT_USER_ACTION]() {
    await fb.logout()
    router.push('/auth/login')
  },

  async [UsersAction.RESET_USER_PASSWORD](_context, { email }) {
    try {
      await fb.TriggerPasswordReset(email)
      notification.success({
        message: 'Reset Link Sent',
        description: 'Password Reset link sent to email id.',
      })
    } catch (error) {
      notification.warning({
        message: error?.code ?? 'Error',
        description: error?.message ?? 'Please try again later.',
      })
    }
  },

  async [UsersAction.UPDATE_BASIC_USER_PROFILE](context, payload) {
    try {
      // TODO: Add more updates in future like Bday.
      if (payload?.name) {
        await fb.UpdateUserName(payload.name)
        context.commit(UsersMutation.SET_USER_PROPERTIES, { name: payload.name })
      }

      const url =
        payload?.action?.toLowerCase() === 'create'
          ? await fb.UploadUserPhoto(payload?.photoFile, payload?.id)
          : null

      // Proceed only if combination is valid.
      // Case: Say there is only name update. So photfile will be null but action will be 'create'
      // which will make url null resulting in wrong update.
      if (
        (url === null && payload?.action?.toLowerCase() === 'delete') ||
        (url != null && payload.action?.toLowerCase() === 'create')
      ) {
        await fb.UpdateUserPhotoUrl(url)
        context.commit(UsersMutation.SET_USER_PROPERTIES, { photoUrl: url })
      }
      notifySuccess(`Profile Updated`, 'Your Profile has been updated')
    } catch (error) {
      notifyWarning(error?.code, error?.message)
    }
  },

  async [UsersAction.GET_APP_USERS]() {
    try {
      const users = await fb.GetAppUsers()
      return users
    } catch (error) {
      notification.warning({
        message: error?.code ?? 'Error',
        description: error?.message ?? 'Please try again later.',
      })
    }
  },

  async [UsersAction.SET_APP_USER_ROLE](context, payload) {
    try {
      const resp = await fb.SetAppUserRole(payload)
      if (resp.result.isSuccess) {
        notifySuccess('Successful', 'User role has been set')
      } else {
        notifyWarning({ code: resp.result.errorCode, message: resp.result.errorMessage })
      }
      return resp.responseData
    } catch (error) {
      notifyWarning(error)
    }
  },
}

function GetUserAuthFromLocalStorage() {
  const userAuth = localStorage.get('app.user.authorized')
  return userAuth === undefined ? false : userAuth
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
