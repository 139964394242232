import {
  GlobalAction,
  CustomersAction,
  VendorsAction,
  VendorServicesAction,
  SettingsAction,
  // ConsignmentsAction,
  CompaniesAction,
} from '../actions.type'
import * as fb from '../../services/firebase/modules/appGlobals'
import {
  GlobalMutation,
  CustomersMutation,
  VendorsMutation,
  ZonesMutation,
  VendorServicesMutation,
  CompaniesMutation,
} from '../mutations.type'
import * as util from './../helpers'
// import * as utils from '@/services/helpers'

const state = () => ({
  isGlobalSetup: false, // TODO: Check how to use isGlobalSetup
  appConfigSettings: {},
  appConfigSecurity: {},
  dashboardInsights: {},
  unsubscribeListenersList: [],
})

const getters = {
  totalConsignments: (state) => (isCurr = false) => {
    return util.getAggregateFromStats(state.dashboardInsights?.stats, 'totalConsignments', isCurr)
  },
  totalInvoices: (state) => (isCurr = false) => {
    return util.getAggregateFromStats(state.dashboardInsights?.stats, 'totalInvoices', isCurr)
  },
  unbilledAmount: (state) => (isCurr = false) => {
    return util.getAggregateFromStats(state.dashboardInsights?.stats, 'unbilledAmount', isCurr)
  },
  totalCashAmount: (state) => (isCurr = false) => {
    return util.getAggregateFromStats(state.dashboardInsights?.stats, 'totalCashAmount', isCurr)
  },
  totalSales: (state) => (isCurr = false) => {
    return util.getAggregateFromStats(state.dashboardInsights?.stats, 'totalSales', isCurr)
  },
  getAppRoles: (state) => {
    return state.appConfigSecurity.roles
  },
}

const mutations = {
  [GlobalMutation.SET_GlOBAL_SETUP](state, payload) {
    state.isGlobalSetup = payload
  },

  [GlobalMutation.SET_APPCONFIG_SETTNGS](state, payload) {
    state.appConfigSettings = payload
  },

  [GlobalMutation.SET_APPCONFIG_SECURITY](state, payload) {
    state.appConfigSecurity = payload
  },

  [GlobalMutation.SET_DASHBOARD_INSIGHTS](state, payload) {
    state.dashboardInsights = payload
  },

  [GlobalMutation.SET_GLOBAL_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [GlobalAction.SETUP_SEARCH_AGGREGATE_LISTENER](context) {
    const unsubscribe = await fb.setupSearchAggregatesListener(
      (searchDocList) => {
        context.commit(GlobalMutation.SET_GlOBAL_SETUP, true)

        // If all data is deleted, it wont update the store as id wont be there so switch case wont work
        if (!(searchDocList?.length > 0)) {
          searchDocList.push(
            ...[
              { id: 'customers' },
              { id: 'vendors' },
              { id: 'vendorServices' },
              { id: 'companies' },
            ],
          )
        }
        searchDocList.forEach((searchDoc) => {
          switch (searchDoc.id) {
            case 'customers': {
              context.commit(
                `customers/${CustomersMutation.SET_CUSTOMERS_SEARCH_LIST}`,
                searchDoc.doc?.customerMapping,
                { root: true },
              )
              break
            }
            case 'vendors': {
              context.commit(
                `vendors/${VendorsMutation.SET_VENDORS_SEARCH_LIST}`,
                searchDoc.doc?.vendorMapping,
                { root: true },
              )
              break
            }
            case 'vendorServices': {
              context.commit(
                `vendorServices/${VendorServicesMutation.SET_VENDOR_SERVICES_SEARCH_LIST}`,
                searchDoc.doc?.serviceMapping,
                { root: true },
              )
              break
            }
            // Check if this is in use.
            case 'vendorZones': {
              context.commit(`zones/${ZonesMutation.SET_ZONES_LOADING}`, true, { root: true })
              context.commit(
                `zones/${ZonesMutation.SET_ZONES_SEARCH_LIST}`,
                searchDoc.doc?.zoneMapping,
                { root: true },
              )
              context.commit(`zones/${ZonesMutation.SET_ZONES_LOADING}`, false, { root: true })
              break
            }
            case 'companies': {
              context.commit(`companies/${CompaniesMutation.SET_COMPANIES_LOADING}`, true, {
                root: true,
              })
              context.commit(
                `companies/${CompaniesMutation.SET_COMPANIES_SEARCH_LIST}`,
                searchDoc.doc?.companyMapping,
                { root: true },
              )
              context.commit(`companies/${CompaniesMutation.SET_COMPANIES_LOADING}`, false, {
                root: true,
              })
              break
            }

            default:
              break
          }
        })
        context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
      },
      () => {
        context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
      },
    )

    context.commit(GlobalMutation.SET_GLOBAL_UNSUBSCRIBE_LISTENERS, unsubscribe)
  },

  async [GlobalAction.LOAD_APPCONFIG_SETTINGS](context) {
    try {
      const unsubscribe = await fb.setupAppConfigSettingsListener(
        (appConfigSettings) => {
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, true)
          context.commit(GlobalMutation.SET_APPCONFIG_SETTNGS, appConfigSettings.doc || {})
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
        },
        () => {
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
        },
      )
      context.commit(GlobalMutation.SET_GLOBAL_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      console.log(error)
      // notifyWarning(error)
    }
  },

  async [GlobalAction.LOAD_APPCONFIG_SECURITY](context) {
    try {
      const unsubscribe = await fb.setupAppConfigSecurityListener(
        (appConfigSecurity) => {
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, true)
          context.commit(GlobalMutation.SET_APPCONFIG_SECURITY, appConfigSecurity.doc || {})
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
        },
        () => {
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
        },
      )
      context.commit(GlobalMutation.SET_GLOBAL_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      console.log(error)
      // notifyWarning(error)
    }
  },

  async [GlobalAction.LOAD_DASHBOARD_INSIGHTS](context) {
    try {
      const unsubscribe = await fb.setupDashboardInsightsListener(
        (dashboardInsights) => {
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, true)
          context.commit(GlobalMutation.SET_DASHBOARD_INSIGHTS, dashboardInsights.doc || {})
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
        },
        () => {
          context.commit(GlobalMutation.SET_GlOBAL_SETUP, false)
        },
      )
      context.commit(GlobalMutation.SET_GLOBAL_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      console.log(error)
      // notifyWarning(error)
    }
  },

  async [GlobalAction.SETUP_GLOBAL_LISTENERS](context) {
    // Add all global listeners here.
    // TODO: Remove fetches (non-listeners) from here and keep only listeners.
    // TODO: Try Promise.All for faster fetch ?
    // TODO: All fetches might not be required. So only load when needed to save cost.
    //       Ex: companies needed for invoices only
    await context.dispatch(`${GlobalAction.LOAD_APPCONFIG_SETTINGS}`)
    await context.dispatch(`${GlobalAction.LOAD_APPCONFIG_SECURITY}`)
    await context.dispatch(`${GlobalAction.LOAD_DASHBOARD_INSIGHTS}`)
    await context.dispatch(`${GlobalAction.SETUP_SEARCH_AGGREGATE_LISTENER}`)
    await context.dispatch(`customers/${CustomersAction.FETCH_CUSTOMERS_ON_LOAD}`, null, {
      root: true,
    })
    await context.dispatch(`companies/${CompaniesAction.FETCH_COMPANIES_ON_LOAD}`, null, {
      root: true,
    })
    await context.dispatch(`vendors/${VendorsAction.FETCH_VENDORS_ON_LOAD}`, null, { root: true })
    await context.dispatch(
      `vendorServices/${VendorServicesAction.FETCH_VENDOR_SERVICES_ON_LOAD}`,
      null,
      {
        root: true,
      },
    )
    await context.dispatch(`${SettingsAction.FETCH_COUNTRY_STATE_DATA}`, null, {
      root: true,
    })
    // zones data will come from services so its not here
    // await context.dispatch(`consignments/${ConsignmentsAction.FETCH_CONSIGNMENTS_ON_LOAD}`, null, {
    //   root: true,
    // })
  },

  async [GlobalAction.UNSUBSCRIBE_GLOBAL_LISTENERS](context) {
    // Unsubscribe global listeners.
    // Call other modules unsubscirbers also.
    context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
  },

  async [GlobalAction.SET_DELETE_SERVICE_CHARGE](context, payload) {
    try {
      const resp = await fb.setOrDeleteServiceCharge(payload)
      if (resp.result.isSuccess) {
        util.notifySuccess(`Service Charge ${payload.action?.toLowerCase()} successfully`)
        return true
      }
      return false
    } catch (error) {
      util.notifyWarning(error)
      return false
    }
  },

  async [GlobalAction.SET_DELETE_TAX](context, payload) {
    try {
      const resp = await fb.setOrDeleteTax(payload)
      if (resp.result.isSuccess) {
        util.notifySuccess(`Tax ${payload.action?.toLowerCase()} successfully`)
        return true
      }
      return false
    } catch (error) {
      util.notifyWarning(error)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
