import * as fb from '../../../services/firebase/modules/vendors'
import { notifySuccess, notifyWarning } from '../../helpers'
import { VendorServicesAction } from '../../actions.type'
import { VendorServicesMutation, ZonesMutation } from '../../mutations.type'
import { DocumentActions } from '@/types/enums/DocumentActions'

const state = () => ({
  vendorServicesIdMap: new Map(),
  vendorServicesLoading: true,
  unsubscribeListenersList: [],
  fetchSize: 30,
  vendorServicesSearchList: [],
  lastDocRef: null,
})

const getters = {
  // GETTERS results are not cached in vue3 yet. So may be move them to state ?
  vendorServicesExists: (state) => state.vendorServicesIdMap.size != 0,
  vendorServiceList: (state) => {
    if (state.vendorServicesIdMap) {
      return [...state.vendorServicesIdMap.values()]
    } else {
      return []
    }
  },
  vendorServicesAliasList: (state) => state.vendorServicesSearchList?.map((x) => x.alias),
  serviceZoneList: (state) => {
    let arrs = []
    const services = [...state.vendorServicesIdMap.values()]
    services.forEach((s) => {
      const serviceId = s.id
      const zoneList = s.doc.zoneList?.map((z) => {
        return { serviceId: serviceId, ...z }
      })
      if (zoneList) {
        arrs = arrs.concat(zoneList)
      }
    })
    return arrs
  },
}

const mutations = {
  [VendorServicesMutation.SET_VENDOR_SERVICES_LOADING](state, payload) {
    state.vendorServicesLoading = payload
  },

  [VendorServicesMutation.SET_VENDOR_SERVICES_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.vendorServicesIdMap = payload
      ? new Map([...state.vendorServicesIdMap, ...payload])
      : new Map()
  },

  [VendorServicesMutation.SET_VENDOR_SERVICES_SEARCH_LIST](state, payload) {
    state.vendorServicesSearchList = payload || []
  },

  [VendorServicesMutation.SET_VENDOR_SERVICES_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [VendorServicesMutation.REMOVE_VENDOR_SERVICE](state, payload) {
    if (state.vendorServicesIdMap?.has(payload)) {
      state.vendorServicesIdMap?.delete(payload)
    }
  },

  [VendorServicesMutation.SET_VENDOR_SERVICES_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [VendorServicesAction.SET_OR_DELETE_VENDOR_SERVICE](context, payload) {
    // TODO: Check payload if op is create
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteVendorService(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Vendor Service ${payload?.action?.toLowerCase()} successful`)
        if (payload.action === DocumentActions.DELETE) {
          context.commit(VendorServicesMutation.REMOVE_VENDOR_SERVICE, payload.id)
        }
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  // async [VendorServicesAction.UPDATE_VENDOR_SERVICE](context, payload) {
  //   // TODO: Use payload data for notification
  //   try {
  //     const resp = await fb.setOrDeleteVendorService(payload)
  //     if (resp.result.isSuccess) {
  //       notifySuccess(`Updated Vendor Service`, `Refresh to see data`)
  //     }
  //   } catch (error) {
  //     notifyWarning(error)
  //   }
  // },

  async [VendorServicesAction.FETCH_VENDOR_SERVICES_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchVendorServicesFromServer(
        payload.fetchSize,
        context.state.lastDocRef,
      )
      context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_MAP, resp.data)
      context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [VendorServicesAction.FETCH_VENDOR_SERVICES_ON_LOAD](context) {
    try {
      context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_LOADING, true)

      // Making vendors map null on refresh, otherwise latest wont show up on top.
      context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_LAST_DOCREF, null)
      context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_MAP, null)

      await context.dispatch(VendorServicesAction.FETCH_VENDOR_SERVICES_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
      })
    } catch (error) {
      notifyWarning(error, true)
    } finally {
      context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_LOADING, false)
      context.commit(`zones/${ZonesMutation.SET_ZONES_LOADING}`, false, { root: true })
    }
  },

  async [VendorServicesAction.UNSUBSCRIBE_VENDOR_SERVICES_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },

  async [VendorServicesAction.FETCH_SERVICE_BY_ID](context, payload) {
    try {
      const docId = payload?.docId
      if (docId) {
        const customersMapRes = await fb.fetchVendorServiceById(docId)
        context.commit(VendorServicesMutation.SET_VENDOR_SERVICES_MAP, customersMapRes)
        return customersMapRes.get(docId)
      } else {
        return null
      }
    } catch (error) {
      notifyWarning(error, true)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
