import * as fb from '../../../services/firebase/modules/invoices'
import { notifySuccess, notifyWarning } from '../../helpers'
import { InvoicesAction } from '../../actions.type'
import { InvoicesMutation } from '../../mutations.type'

const state = () => ({
  invoicesIdMap: new Map(),
  invoicesLoading: false,
  invoicesSearchList: [],
  fetchSize: 15,
  lastDocRef: null,
  unsubscribeListenersList: [],
})

const getters = {
  invoicesExists: (state) => state.invoicesIdMap.size > 0,
  invoiceList: (state) => {
    if (state.invoicesIdMap) {
      return [...state.invoicesIdMap.values()]
    } else {
      return []
    }
  },
}

const mutations = {
  [InvoicesMutation.SET_INVOICES_LOADING](state, payload) {
    state.invoicesLoading = payload
  },

  [InvoicesMutation.SET_INVOICES_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.invoicesIdMap = payload ? new Map([...state.invoicesIdMap, ...payload]) : new Map()
  },

  [InvoicesMutation.SET_INVOICES_SEARCH_LIST](state, payload) {
    state.invoicesSearchList = payload || []
  },

  [InvoicesMutation.SET_INVOICE_STATUS](state, payload) {
    if (state.invoicesIdMap?.has(payload.docId)) {
      const appDoc = state.invoicesIdMap?.get(payload.docId)
      appDoc.doc.invoiceStatus = payload.status
      appDoc.doc.balanceDue = payload.balanceDue
    }
  },

  [InvoicesMutation.REMOVE_INVOICE](state, payload) {
    if (state.invoicesIdMap?.has(payload)) {
      state.invoicesIdMap?.delete(payload)
    }
  },

  [InvoicesMutation.SET_INVOICES_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [InvoicesMutation.SET_INVOICES_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [InvoicesAction.ADD_NEW_INVOICE](context, payload) {
    // TODO: Check payload if op is create
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteInvoice(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Added ${resp.responseData.invid}`, 'Invoice added successfully')
        // await context.dispatch(InvoicesAction.FETCH_INVOICES_BY_IDLIST, {
        //   idList: [resp.responseData?.id],
        // })
        return true
      } else {
        notifyWarning({ code: resp.result.errorCode, message: resp.result.errorMessage })
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  async [InvoicesAction.UPDATE_INVOICE](context, payload) {
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteInvoice(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Updated Invoice`, `Refresh to see data`)
      }
    } catch (error) {
      notifyWarning(error)
    }
  },

  async [InvoicesAction.DELETE_INVOICE](context, payload) {
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteInvoice(payload)
      if (resp.result.isSuccess) {
        context.commit(InvoicesMutation.REMOVE_INVOICE, resp.responseData.id)
        notifySuccess(`Deleted Invoice ${resp.responseData.invid}`, `Refresh to see data`)
      }
    } catch (error) {
      notifyWarning(error)
    }
  },

  async [InvoicesAction.FETCH_INVOICES_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchInvoicesFromServer(
        payload.fetchSize,
        context.state.lastDocRef,
        payload.filters,
      )
      context.commit(InvoicesMutation.SET_INVOICES_MAP, resp.data)
      context.commit(InvoicesMutation.SET_INVOICES_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      console.log(error)
      notifyWarning(error, true)
    }
  },

  async [InvoicesAction.FETCH_INVOICES_ON_LOAD](context, payload) {
    try {
      context.commit(InvoicesMutation.SET_INVOICES_LOADING, true)

      // Making invoices map null on refresh, otherwise latest wont show up on top.
      context.commit(InvoicesMutation.SET_INVOICES_LAST_DOCREF, null)
      context.commit(InvoicesMutation.SET_INVOICES_MAP, null)

      await context.dispatch(InvoicesAction.FETCH_INVOICES_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
        filters: payload?.filters,
      })
    } catch (error) {
      console.log(error)
      notifyWarning(error, true)
    } finally {
      context.commit(InvoicesMutation.SET_INVOICES_LOADING, false)
    }
  },

  async [InvoicesAction.FETCH_INVOICES_BY_ID](context, payload) {
    try {
      const invoiceIdAppDoc = payload?.invid
        ? await fb.fetchInvoiceByInvId(payload?.invid)
        : payload?.docId
        ? await fb.fetchInvoiceByDocId(payload.docId)
        : null
      return invoiceIdAppDoc
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  // async [InvoicesAction.LOAD_INVOICES_SEARCH_BY_LISTENERS](context) {
  //   try {
  //     const unsubscribe = await fb.setupInvoicesListener(
  //       (invoicesSearchDoc) => {
  //         context.commit(InvoicesMutation.SET_INVOICES_LOADING, true)
  //         context.commit(
  //           InvoicesMutation.SET_INVOICES_SEARCH_LIST,
  //           invoicesSearchDoc.doc?.invoiceMapping,
  //         )
  //         context.commit(InvoicesMutation.SET_INVOICES_LOADING, false)
  //       },
  //       () => {
  //         context.commit(InvoicesMutation.SET_INVOICES_LOADING, false)
  //       },
  //     )
  //     context.commit(InvoicesMutation.SET_INVOICES_UNSUBSCRIBE_LISTENERS, unsubscribe)
  //   } catch (error) {
  //     // TODO: Handle Error.
  //     console.log(error)
  //     notifyWarning(error)
  //   }
  // },

  async [InvoicesAction.UNSUBSCRIBE_INVOICES_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },

  async [InvoicesAction.DOWNLOAD_INVOICE](context, payload) {
    try {
      if (!payload || !payload.custId || !payload.invId) {
        notifyWarning({ message: 'Invalid Cust/Inv Id.' })
        return null
      } else {
        const opResp = await fb.downloadInvoice(payload.custId, payload.invId)
        if (!opResp.result.isSuccess) {
          notifyWarning({ code: opResp.result.errorCode, message: opResp.result.errorMessage })
          return null
        }
        return opResp.responseData
      }
    } catch (error) {
      notifyWarning(error)
      return null
    }
  },

  async [InvoicesAction.UPDATE_INVOICE_STATUS](context, payload) {
    try {
      if (!payload || !payload.status) {
        notifyWarning({ message: 'Invalid Data.' })
        return null
      } else {
        // const bln = await fb.updateInvoiceStatus(payload.docId, payload.status, payload.amount)
        // Note - We are sending InvoicesDoc but expected is InvoiceFormData.
        // Since both have similar structure we didnt recreate invoice form data.
        payload.docOperation.payload.invoiceStatus = payload.status
        const resp = await fb.setOrDeleteInvoice(payload.docOperation)
        context.commit(InvoicesMutation.SET_INVOICE_STATUS, {
          docId: resp.responseData.id,
          status: resp.responseData.status,
          balanceDue: resp.responseData.balanceDue,
        })
        // Invoice status has been changed to Sent.
      }
    } catch (error) {
      notifyWarning(error)
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
