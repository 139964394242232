import * as fb from '../../../services/firebase/modules/consignments'
import { notifySuccess, notifyWarning } from '../../helpers'
import { ConsignmentsAction } from '../../actions.type'
import { ConsignmentsMutation } from '@/store/mutations.type'
import { DocumentActions } from '@/types/enums/DocumentActions'

const state = () => ({
  consignmentsIdMap: new Map(),
  consignmentsLoading: false,
  unsubscribeListenersList: [],
  fetchSize: 15,
  lastDocRef: null,
})

const getters = {
  consignmentsExists: (state) => state.consignmentsIdMap?.size != 0,
  consignmentList: (state) => {
    if (state.consignmentsIdMap) {
      return [...state.consignmentsIdMap.values()]
    } else {
      return []
    }
  },
}

const mutations = {
  [ConsignmentsMutation.SET_CONSIGNMENTS_LOADING](state, payload) {
    state.consignmentsLoading = payload
  },

  [ConsignmentsMutation.SET_CONSIGNMENTS_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.consignmentsIdMap = payload
      ? new Map([...state.consignmentsIdMap, ...payload])
      : new Map()
  },

  [ConsignmentsMutation.SET_CONSIGNMENTS_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [ConsignmentsMutation.REMOVE_CONSIGNMENT](state, payload) {
    if (state.consignmentsIdMap?.has(payload)) {
      state.consignmentsIdMap?.delete(payload)
    }
  },

  [ConsignmentsMutation.SET_CONSIGNMENTS_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  // TODO: Either ADD or UPDATE can be removed as its a upsert op.
  // async [ConsignmentsAction.ADD_NEW_CONSIGNMENT](context, payload) {
  //   // TODO: Check payload if op is create
  //   // TODO: Use payload data for notification
  //   try {
  //     const resp = await fb.setOrDeleteConsignment(payload)
  //     if (resp.result.isSuccess) {
  //       notifySuccess(`Added Consignment`)
  //       // await context.dispatch(ConsignmentsAction.FETCH_CONSIGNMENTS_BY_IDLIST, {
  //       //   idList: [resp.responseData?.id],
  //       // })
  //       return true
  //     }
  //     return false
  //   } catch (error) {
  //     notifyWarning(error)
  //     return false
  //   }
  // },

  async [ConsignmentsAction.SET_OR_DELETE_CONSIGNMENT](context, payload) {
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteConsignment(payload)
      if (resp.result.isSuccess) {
        if (payload.action !== DocumentActions.DELETE) {
          notifySuccess(
            `Consignment ${payload?.action?.toLowerCase()} successful`,
            `Refresh to see data`,
          )
        } else {
          context.commit(ConsignmentsMutation.REMOVE_CONSIGNMENT, payload.id)
          notifySuccess('Consignment Deleted.')
        }
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  async [ConsignmentsAction.FETCH_CONSIGNMENTS_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchConsignmentsFromServer(
        payload.fetchSize,
        context.state.lastDocRef,
        payload.filters,
      )
      context.commit(ConsignmentsMutation.SET_CONSIGNMENTS_MAP, resp.data)
      context.commit(ConsignmentsMutation.SET_CONSIGNMENTS_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      console.log(error)
      notifyWarning(error, true)
    }
  },

  async [ConsignmentsAction.FETCH_CONSIGNMENTS_ON_LOAD](context, payload) {
    try {
      context.commit(ConsignmentsMutation.SET_CONSIGNMENTS_LOADING, true)

      // Making consignments map null on refresh, otherwise latest wont show up on top.
      context.commit(ConsignmentsMutation.SET_CONSIGNMENTS_LAST_DOCREF, null)
      context.commit(ConsignmentsMutation.SET_CONSIGNMENTS_MAP, null)

      await context.dispatch(ConsignmentsAction.FETCH_CONSIGNMENTS_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
        filters: payload?.filters,
      })
    } catch (error) {
      console.log(error)
      notifyWarning(error, true)
    } finally {
      context.commit(ConsignmentsMutation.SET_CONSIGNMENTS_LOADING, false)
    }
  },

  async [ConsignmentsAction.UNSUBSCRIBE_CONSIGNMENTS_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },

  async [ConsignmentsAction.FETCH_CONSIGNMENT_DATA_ID](context, payload) {
    try {
      // TODO: Method has diff return types. Please correct.
      if (!payload.awb && !payload.rfn && !payload.trackId) {
        return null
      }
      if (payload.awb) {
        const resp = await fb.fetchConsignmentData(payload.awb)
        return resp
      } else if (payload.rfn) {
        const resp = await fb.fetchConsignmentDataByRfn(payload.rfn)
        return resp
      } else {
        const resp = await fb.fetchConsignmentDataByAwbOrRfn(payload.trackId)
        return resp
      }
    } catch (error) {
      notifyWarning(error, true)
      return null
    }
  },

  async [ConsignmentsAction.FETCH_INVOICE_CONSIGNMENT_DATA](context, payload) {
    try {
      const resp = await fb.fetchConsignmentDataForInvoice(payload)
      return resp
    } catch (error) {
      notifyWarning(error, true)
      return null
    }
  },

  async [ConsignmentsAction.GET_SHIPMENT_TRACKING](context, payload) {
    try {
      const resp = await fb.getShipmentTracking(payload)
      return resp.responseData // { latestStatus: 'InfoReceived' }
    } catch (error) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
