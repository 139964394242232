import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { IStats } from '@/types/interfaces/IStats'
import { notification } from 'ant-design-vue'

export function notifyWarning(error: any, isReadError: boolean = false) {
  if (isReadError && error.code === 'permission-denied') {
    return
  }
  notification.warning({
    message: error?.code ?? 'Error',
    description: error?.message ?? 'Please try again later.',
  })
}

export function notifySuccess(message: string, description: string) {
  notification.success({
    message: message,
    description: description,
  })
}

export function getAggregateFromStats(
  stats: IStats[],
  key: keyof IStats,
  isCurrFy: boolean = false,
) {
  if (isCurrFy) {
    return (
      stats?.reduce((prev, curr) => {
        if (isCurrentFy(curr.month, curr.year)) {
          return prev + ((curr[key] as number) || 0)
        } else {
          return prev
        }
      }, 0) || 0
    )
  } else {
    const total = stats?.reduce((prev, curr) => prev + ((curr[key] as number) || 0), 0) || 0
    return total
  }
}

export const isCurrentFy = (month: number, year: number) => {
  const currYear = new Date().getFullYear()
  const currMonth = new Date().getMonth()
  const arr = [currYear, currYear]
  if (currMonth < 3) {
    arr[0] = currYear - 1
  } else {
    arr[1] = currYear + 1
  }
  if ((month < 3 && year === arr[1]) || (month > 3 && year === arr[0])) {
    return true
  }
  return false
}

export const removeZoneRate = (appDoc: IAppDocument<ShippingRatesDocument>, zoneId: string) => {
  if (appDoc && zoneId) {
    appDoc.doc.zoneRates?.splice(
      appDoc.doc.zoneRates.findIndex((x) => x.zoneId === zoneId),
      1,
    )
  }
}
