import * as fb from '../../../services/firebase/modules/rates'
import { notifySuccess, notifyWarning } from '../../helpers'
import { RatesAction } from '../../actions.type'
import { RatesMutation } from '../../mutations.type'
import { DocumentActions } from '@/types/enums/DocumentActions'
import * as util from '../../helpers'

const state = () => ({
  shippingRatesIdMap: new Map(),
  shippingRatesLoading: true,
  fetchSize: 15,
  lastDocRef: null,
})

const getters = {
  shippingRatesList: (state) => {
    if (state.shippingRatesIdMap) {
      return [...state.shippingRatesIdMap.values()]
    } else {
      return []
    }
  },
}

const mutations = {
  [RatesMutation.SET_SHIPPING_RATES_LOADING](state, payload) {
    state.shippingRatesLoading = payload
  },

  [RatesMutation.SET_SHIPPING_RATES_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.shippingRatesIdMap = payload
      ? new Map([...state.shippingRatesIdMap, ...payload])
      : new Map()
  },

  [RatesMutation.REMOVE_SHIPPING_RATE](state, payload) {
    if (!payload.zoneId) {
      state.shippingRatesIdMap.delete(payload.id)
    } else {
      const appDoc = state.shippingRatesIdMap.get(payload.id)
      util.removeZoneRate(appDoc, payload.zoneId)
    }
  },

  [RatesMutation.SET_SHIPPING_RATES_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },
}

const actions = {
  async [RatesAction.SET_OR_DELETE_SHIPPING_RATE](context, payload) {
    // TODO: Check payload if op is create
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteShippingRate(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Shipping Rate ${payload?.action?.toLowerCase()} successful`)
        if (payload.action === DocumentActions.DELETE) {
          context.commit(RatesMutation.REMOVE_SHIPPING_RATE, {
            id: payload.id,
            zoneId: payload.payload?.zoneId || null,
          })
        }
        // await context.dispatch(RatesAction.FETCH_SHIPPING_RATES_BY_IDLIST, {
        //   idList: [resp.responseData?.id],
        // })
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  // async [RatesAction.UPDATE_SHIPPING_RATE](context, payload) {
  //   // TODO: Use payload data for notification
  //   try {
  //     const resp = await fb.setOrDeleteShippingRate(payload)
  //     if (resp.result.isSuccess) {
  //       notifySuccess(`Updated Rate`, `Refresh to see data`)
  //     }
  //   } catch (error) {
  //     notifyWarning(error)
  //   }
  // },

  async [RatesAction.FETCH_SHIPPING_RATES_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchRatesFromServer(payload.fetchSize, context.state.lastDocRef)
      context.commit(RatesMutation.SET_SHIPPING_RATES_MAP, resp.data)
      context.commit(RatesMutation.SET_SHIPPING_RATES_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [RatesAction.FETCH_SHIPPING_RATES_ON_LOAD](context) {
    try {
      context.commit(RatesMutation.SET_SHIPPING_RATES_LOADING, true)

      // Making shippingRates map null on refresh, otherwise latest wont show up on top.
      context.commit(RatesMutation.SET_SHIPPING_RATES_LAST_DOCREF, null)
      context.commit(RatesMutation.SET_SHIPPING_RATES_MAP, null)

      await context.dispatch(RatesAction.FETCH_SHIPPING_RATES_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
      })
    } catch (error) {
      notifyWarning(error, true)
    } finally {
      context.commit(RatesMutation.SET_SHIPPING_RATES_LOADING, false)
    }
  },

  async [RatesAction.FETCH_RATES_BY_ID](context, payload) {
    try {
      const docId = payload?.docId
      if (docId) {
        const customersMapRes = await fb.fetchRateByDocId(docId)
        context.commit(RatesMutation.SET_SHIPPING_RATES_MAP, customersMapRes)
        return customersMapRes.get(docId)
      } else {
        return null
      }
    } catch (error) {
      notifyWarning(error, true)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
