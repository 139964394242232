import { OperationResponse } from '@/types/appContracts/OperationResponse'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
import * as fb from './index'

export async function callFunction<T>(functionName: string, data: any = null) {
  const func = fb.firebaseFunctions.httpsCallable(functionName)
  const res = data ? await func(data) : await func()
  return res?.data as OperationResponse<T>
}

export async function uploadToStorageAndGetLink(
  file: any,
  path: string | null,
): Promise<string | null> {
  if (file && path) {
    const resp = await fb.storageRootRef.child(path).put(file)
    const url = (await resp.ref.getDownloadURL()) as string
    return url
  }

  return null
}

export function getModifiedByData() {
  const user = fb.firebaseAuth.currentUser
  return {
    'audit.modifiedOn': fb.dbFieldValue.serverTimestamp(),
    'audit.modifiedById': user?.uid,
    'audit.modifiedByEmail': user?.email,
  }
}

/**
 * Fetch Data from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 */
export async function fetchDocumentsFromServerByOrder<T>(
  collectionName: FirebaseCollections,
  orderByFieldPath: string,
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
  order: 'asc' | 'desc' = 'desc',
  filters: IFilterWhereQuery[] | null = null,
) {
  const dataIdMap = new Map<string, IAppDocument<T>>()

  let query = fb.db.collection(collectionName).limit(fetchSize)

  if (filters && filters?.length > 0) {
    filters.forEach((q) => {
      query = query.where(q.fieldName, q.operator, q.value)
    })
  } else {
    query = query.orderBy(orderByFieldPath, order)
  }

  const serverDocs = lastDocRef
    ? await query.startAfter(lastDocRef).get({ source: 'server' })
    : await query.get({ source: 'server' })

  serverDocs.forEach((doc) => dataIdMap.set(doc.id, { id: doc.id, doc: doc.data() as T }))
  // console.log('fetchVendorsFromServer: Server Size - ', serverDocs.docs?.length)

  if (!serverDocs.empty) {
    lastDocRef = serverDocs.docs[serverDocs.docs.length - 1]
  }
  const resp = { data: dataIdMap, lastDoc: lastDocRef }
  return resp
}

export function convertFirestoreTimestampToDate(val: unknown) {
  const conv = (val as firebase.default.firestore.Timestamp)?.toDate() || null
  return conv
}
