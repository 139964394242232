export const UsersAction = {
  LOGIN_USER_ACTION: 'loginUser',
  REGISTER_USER_ACTION: 'registerUser',
  LOGOUT_USER_ACTION: 'logoutUser',
  LOAD_CURRENT_USER_ACTION: 'loadCurrentUser',
  RESET_USER_PASSWORD: 'resetUserPassword',
  GET_APP_USERS: 'getAppUsers',
  SET_APP_USER_ROLE: 'setAppUserRole',
  UPDATE_BASIC_USER_PROFILE: 'updateBasicUserProfile',
}

export const CustomersAction = {
  SET_OR_DELETE_CUSTOMER: 'setOrDeleteCustomer',
  SET_OR_DELETE_REFISSUE: 'setOrDeleteRefIssue',
  FETCH_CUSTOMERS_BY_IDLIST: 'fetchCustomersByDocIdList',
  FETCH_CUSTOMERS_ON_LOAD: 'fetchCustomersOnLoad',
  FETCH_CUSTOMERS_FROM_SERVER: 'fetchCustomersFromServer',
  FETCH_CUSTOMER_PROFILE: 'fetchCustomerProfile',
  UPDATE_CUSTOMER_PHOTO: 'updateCustomerPhoto',
  UNSUBSCRIBE_CUSTOMERS_LISTENERS: 'unsubscribeCustomersListeners',
  LOAD_CUSTOMERS_SEARCH_BY_LISTENERS: 'loadCustomersSearchByListeners',
}

export const VendorsAction = {
  SET_OR_DELETE_VENDOR: 'setOrDeleteVendor',
  // UPDATE_VENDOR: 'updateVendor',
  FETCH_VENDOR_PROFILE: 'fetchVendorProfile',
  FETCH_VENDORS_FROM_SERVER: 'fetchVendorsFromServer',
  FETCH_VENDORS_ON_LOAD: 'fetchVendorsOnLoad',
  UNSUBSCRIBE_VENDORS_LISTENERS: 'unsubscribeVendorsListeners',
  LOAD_VENDORS_ALIAS_BY_LISTENERS: 'loadVendorsAliasByListeners',
}

export const VendorServicesAction = {
  SET_OR_DELETE_VENDOR_SERVICE: 'setOrDeleteVendorService',
  // UPDATE_VENDOR_SERVICE: 'updateVendorService',
  FETCH_VENDOR_SERVICES_FROM_SERVER: 'fetchVendorServicesFromServer',
  FETCH_VENDOR_SERVICES_ON_LOAD: 'fetchVendorServicesOnLoad',
  FETCH_SERVICE_BY_ID: 'fetchServiceById',
  UNSUBSCRIBE_VENDOR_SERVICES_LISTENERS: 'unsubscribeVendorServicesListeners',
}

export const ZonesAction = {
  SET_OR_DELETE_ZONE: 'setOrDeleteZone',
  // UPDATE_ZONE: 'updateZone',
  FETCH_ZONES_FROM_SERVER: 'fetchZonesFromServer',
  FETCH_ZONES_ON_LOAD: 'fetchZonesOnLoad',
  UNSUBSCRIBE_ZONES_LISTENERS: 'unsubscribeZonesListeners',
  LOAD_ZONES_SEARCH_BY_LISTENERS: 'loadZonesSearchByListeners',
}

export const ConsignmentsAction = {
  // ADD_NEW_CONSIGNMENT: 'addNewConsignment',
  SET_OR_DELETE_CONSIGNMENT: 'setOrDeleteConsignment',
  FETCH_CONSIGNMENTS_FROM_SERVER: 'fetchConsignmentsFromServer',
  FETCH_CONSIGNMENTS_ON_LOAD: 'fetchConsignmentsOnLoad',
  UNSUBSCRIBE_CONSIGNMENTS_LISTENERS: 'unsubscribeConsignmentsListeners',
  FETCH_CONSIGNMENT_DATA_ID: 'fetchConsignmentDataById',
  FETCH_INVOICE_CONSIGNMENT_DATA: 'fetchInvoiceConsignmentData',
  GET_SHIPMENT_TRACKING: 'getShipmentTracking',
}

export const RatesAction = {
  SET_OR_DELETE_SHIPPING_RATE: 'setOrDeleteShippingRate',
  // UPDATE_SHIPPING_RATE: 'updateShippingRate',
  FETCH_SHIPPING_RATES_FROM_SERVER: 'fetchShippingRatesFromServer',
  FETCH_SHIPPING_RATES_ON_LOAD: 'fetchShippingRatesOnLoad',
  FETCH_RATES_BY_ID: 'fetchRatesById',
}

export const CompaniesAction = {
  SET_OR_DELETE_COMPANY: 'setOrDeleteCompany',
  ADD_NEW_COMPANY: 'addNewCompany',
  UPDATE_COMPANY: 'updateCompany',
  FETCH_COMPANIES_FROM_SERVER: 'fetchCompaniesFromServer',
  FETCH_COMPANIES_ON_LOAD: 'fetchCompaniesOnLoad',
  FETCH_COMPANIES_BY_ID: 'fetchCompaniesById',
  LOAD_COMPANIES_SEARCH_BY_LISTENERS: 'loadCompaniesSearchByListeners',
  UNSUBSCRIBE_COMPANIES_LISTENERS: 'unsubscribeCompaniesListeners',
}

export const InvoicesAction = {
  ADD_NEW_INVOICE: 'addNewInvoice',
  UPDATE_INVOICE: 'updateInvoice',
  DELETE_INVOICE: 'deleteInvoice',
  FETCH_INVOICES_FROM_SERVER: 'fetchInvoicesFromServer',
  FETCH_INVOICES_ON_LOAD: 'fetchInvoicesOnLoad',
  FETCH_INVOICES_BY_ID: 'fetchInvoicesById',
  DOWNLOAD_INVOICE: 'downloadInvoice',
  UPDATE_INVOICE_STATUS: 'updateInvoiceStatus',
  LOAD_INVOICES_SEARCH_BY_LISTENERS: 'loadInvoicesSearchByListeners',
  UNSUBSCRIBE_INVOICES_LISTENERS: 'unsubscribeInvoicesListeners',
}

export const ToolsAction = {
  READ_IMPORT_FILE: 'readImportFile',
  GET_SUPPORTED_ENTITIES: 'getSupportedEntities',
  IMPORT_ENTITY_DATA: 'importEntityData',
  DOWNLOAD_ENTITY_SAMPLE: 'downloadEntitySample',
}

export const SettingsAction = {
  FETCH_COUNTRY_STATE_DATA: 'fetchCountryStateData',
  FETCH_STATES_BY_COUNTRY: 'fetchStatesByCountry',
  FETCH_CITIES_BY_COUNTRY: 'fetchCitiesByCountry',
}

export const GlobalAction = {
  SETUP_SEARCH_AGGREGATE_LISTENER: 'setupSearchAggregatesListener',
  SETUP_GLOBAL_LISTENERS: 'setupGlobalListeners',
  LOAD_APPCONFIG_SETTINGS: 'loadAppConfigSettings',
  LOAD_APPCONFIG_SECURITY: 'loadAppConfigSecurity',
  LOAD_DASHBOARD_INSIGHTS: 'loadDashboardInsights',
  SET_DELETE_SERVICE_CHARGE: 'setOrDeleteServiceCharge',
  SET_DELETE_TAX: 'setOrDeleteTax',
  UNSUBSCRIBE_GLOBAL_LISTENERS: 'unsubscribeGlobalListeners',
}
