import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-browser.prod'

import english from '@/locales/en-US'

const locales = {
  'en-US': english,
}

export const i18n = createI18n({
  // legacy: false,
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: {
    'en-US': locales['en-US'].messages,
  },
})
