export enum FirebaseCollections {
  Customers = 'customers',
  Vendors = 'vendors',
  CustomerBooks = 'customerBooks',
  SearchAggregates = 'searchAggregates',
  DataImports = 'dataImports',
  VendorServices = 'vendorServices',
  VendorZones = 'vendorZones',
  ShippingLocations = 'shippingLocations',
  ShippingRates = 'shippingRates',
  Consignments = 'consignments',
  Dashboard = 'dashboard',
  AppConfigurations = 'appConfigurations',
  Invoices = 'invoices',
  Companies = 'companies',
  RefIssues = 'refIssues',
}
