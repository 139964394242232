import { fetchCountryStateData } from '@/services/firebase/modules/settings'
import store from 'store'
import { SettingsAction } from '../actions.type'
import { SettingsMutation } from '../mutations.type'

const STORED_SETTINGS = (storedSettings) => {
  const settings = {}
  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

export default {
  state: {
    ...STORED_SETTINGS({
      authProvider: 'firebase',
      logo: 'Power Media',
      version: 'clean',
      theme: 'default',
      locale: 'en-US',
      isSidebarOpen: false,
      isSupportChatOpen: false,
      isMobileView: false,
      isMobileMenuOpen: false,
      isMenuCollapsed: false,
      isPreselectedOpen: false,
      preselectedVariant: 'default',
      menuLayoutType: 'left',
      routerAnimation: 'slide-fadein-up',
      menuColor: 'gray',
      authPagesColor: 'gray',
      isAuthTopbar: true,
      primaryColor: '#4b7cf3',
      leftMenuWidth: 256,
      isMenuUnfixed: false,
      isMenuShadow: false,
      isTopbarFixed: false,
      isTopbarSeparated: false,
      isGrayTopbar: false,
      isContentMaxWidth: false,
      isAppMaxWidth: false,
      isGrayBackground: false,
      isCardShadow: true,
      isSquaredBorders: false,
      isBorderless: false,
      layoutMenu: 'classic',
      layoutTopbar: 'v1',
      layoutBreadcrumbs: 'v1',
      layoutFooter: 'v1',
      flyoutMenuType: 'flyout',
      flyoutMenuColor: 'blue',
      countries: null,
      indianStates: null,
      indianCities: null,
    }),
  },
  mutations: {
    CHANGE_SETTING(state, payload) {
      store.set(`app.settings.${payload.setting}`, payload.value)
      state[payload.setting] = payload.value
    },
    CHANGE_SETTING_BULK(state, payload) {
      const settings = {}
      Object.keys(payload).forEach((key) => {
        store.set(`app.settings.${key}`, payload[key])
        settings[key] = payload[key]
        state[key] = payload[key]
      })
    },
    SETUP_URL_SETTINGS(state, payload) {
      let queryParams = payload
      let keys = false
      if (payload.redirect) {
        const str = payload.redirect
        const subs = str.substring(str.indexOf('?') + 1)
        if (str.indexOf('?') >= 0) {
          queryParams = JSON.parse(
            '{"' +
              decodeURI(subs)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}',
          )
        }
      }
      delete queryParams.redirect
      keys = Object.keys(queryParams)
      if (keys.length) {
        keys.forEach((key) => {
          let value
          switch (queryParams[key]) {
            case 'false':
              value = false
              break
            case 'true':
              value = true
              break
            default:
              value = queryParams[key]
              break
          }
          if (key in state) {
            state[key] = value
          }
        })
      }
    },
    SET_PRIMARY_COLOR(state, payload) {
      const { color } = payload
      const addStyles = () => {
        const styleElement = document.querySelector('#primaryColor')
        if (styleElement) {
          styleElement.remove()
        }
        const body = document.querySelector('body')
        const styleEl = document.createElement('style')
        const css = document.createTextNode(`:root { --vb-color-primary: ${color};}`)
        styleEl.setAttribute('id', 'primaryColor')
        styleEl.appendChild(css)
        body.appendChild(styleEl)
      }
      addStyles()
      state.primaryColor = color
      store.set('app.settings.primaryColor', color)
    },
    SET_THEME(state, payload) {
      const { theme } = payload
      const nextTheme = theme === 'dark' ? 'dark' : 'default'
      document.querySelector('html').setAttribute('data-vb-theme', nextTheme)
      state.theme = nextTheme
      store.set('app.settings.theme', nextTheme)
    },
    [SettingsMutation.SET_COUNTRIES](state, payload) {
      state.countries = payload
      store.set('app.settings.countries', payload)
    },
    [SettingsMutation.SET_STATES](state, payload) {
      state.indianStates = payload
      store.set('app.settings.indianStates', payload)
    },
    [SettingsMutation.SET_CITIES](state, payload) {
      state.indianCities = payload
      store.set('app.settings.indianCities', payload)
    },
  },
  actions: {
    async [SettingsAction.FETCH_COUNTRY_STATE_DATA](context) {
      // const allCountryPayload = {}
      const countries = store.get(`app.settings.countries`)
      const states = store.get(`app.settings.indianStates`)
      const cities = store.get(`app.settings.indianCities`)

      if (!countries?.length > 0) {
        const payload = {
          methodType: 'getAllCountries',
          countryCode: null,
          stateCode: null,
          cityCode: null,
        }
        const data = await fetchCountryStateData(payload)
        context.commit(SettingsMutation.SET_COUNTRIES, data.responseData)
      }
      if (!states?.length > 0) {
        const payload = {
          methodType: 'getStatesOfCountry',
          countryCode: 'IN',
          stateCode: null,
          cityCode: null,
        }
        const data = await fetchCountryStateData(payload)
        context.commit(SettingsMutation.SET_STATES, data.responseData)
      }
      if (!cities?.length > 0) {
        const payload = {
          methodType: 'getCitiesOfCountry',
          countryCode: 'IN',
          stateCode: null,
          cityCode: null,
        }
        const data = await fetchCountryStateData(payload)
        context.commit(SettingsMutation.SET_CITIES, data.responseData)
      }
    },

    async [SettingsAction.FETCH_STATES_BY_COUNTRY](context, payload) {
      // const allCountryPayload = {}
      // const countries = store.get(`app.settings.countries`)
      // const states = store.get(`app.settings.indianStates`)
      // const cities = store.get(`app.settings.indianCities`)

      if (!payload?.countryCode) {
        return null
      }
      const query = {
        methodType: 'getStatesOfCountry',
        countryCode: payload.countryCode,
        stateCode: null,
        cityCode: null,
      }
      const data = await fetchCountryStateData(query)
      return data.responseData
      // context.commit(SettingsMutation.SET_STATES, data.responseData)
    },

    async [SettingsAction.FETCH_CITIES_BY_COUNTRY](context, payload) {
      if (!payload?.countryCode) {
        return null
      }
      const query = {
        methodType: 'getCitiesOfCountry',
        countryCode: payload.countryCode,
        stateCode: null,
        cityCode: null,
      }
      const data = await fetchCountryStateData(query)
      return data.responseData
    },
  },
  getters: {
    settings: (state) => state,
  },
}
