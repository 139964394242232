<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <router-link to="/">Home</router-link>
      <template v-for="(item, index) in breadcrumb" :key="index">
        <span>
          <span :class="$style.arrow"></span>
          <template v-if="item.title === activeItem.title">
            <span>
              <strong :class="$style.current">{{ activeItem.title }}</strong>
            </span>
          </template>
          <template v-else>
            <router-link :to="item.url || '#'">
              <span>{{ item.title }}</span>
            </router-link>
          </template>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getMenuData } from '@/services/menu'
import reduce from 'lodash/reduce'

export default {
  name: 'Breadcrumbs',
  setup() {
    const route = useRoute()
    const breadcrumb = ref([])
    const activeItem = ref([])
    const menuData = getMenuData
    const routePath = computed(() => route.path)

    const getPath = (data, url, parents = []) => {
      if (url === '/') {
        url = '/dashboard'
      }
      const items = reduce(
        data,
        (result, entry) => {
          // Since existing entry cannot edited, creating a clone.
          // Case: When params will be replaced.
          const newEntry = { ...entry }

          if (result.length) {
            return result
          }
          if (entry.children) {
            const nested = getPath(entry.children, url, [entry].concat(parents))
            return (result || []).concat(nested.filter((e) => !!e))
          }

          // Check if url contains any param that needs to replaced.
          if (entry.params) {
            newEntry.url = replaceValues(entry.url, route.params)
          }

          if (newEntry.url === url) {
            return [newEntry].concat(parents)
          }

          // Check if subpaths exists.
          if (entry.subpaths) {
            const nested = getPath(entry.subpaths, url, [newEntry].concat(parents))
            return (result || []).concat(nested.filter((e) => !!e))
          }
          return result
        },
        [],
      )
      activeItem.value = items[0]
      return items
    }

    onMounted(() => {
      breadcrumb.value = getPath(menuData, routePath.value).reverse()
    })

    watch(routePath, (routePath) => {
      breadcrumb.value = getPath(menuData, routePath).reverse()
    })

    // Replaces :params with actual values.
    // Ex: /sales/customers/:id/profile => m=/:id, g1=/, g2=id
    // TODO: Move to a common place.
    const replaceValues = (str, params) =>
      str.replace(/(^|\/):(\w+)(?=\/|$)/g, (m, g1, g2) => g1 + (params[g2] || m))

    return {
      breadcrumb,
      activeItem,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
