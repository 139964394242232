import * as fb from '../../../services/firebase/modules/customers'
import { CustomersMutation } from '../../mutations.type'
import { CustomersAction } from '../../actions.type'
import { notifySuccess, notifyWarning } from '../../helpers'
import { DocumentActions } from '@/types/enums/DocumentActions'

const state = () => ({
  customersMap: new Map(),
  customersLoading: true,
  unsubscribeListenersList: [],
  fetchSize: 15,
  customersSearchList: [],
  lastDocRef: null,
})

const getters = {
  customersExists: (state) => state.customersMap.size != 0,
  customerList: (state) => {
    if (state.customersMap) {
      return [...state.customersMap.values()]
    } else {
      return []
    }
  },
}

const mutations = {
  [CustomersMutation.SET_CUSTOMERS_LOADING](state, payload) {
    state.customersLoading = payload
  },

  [CustomersMutation.SET_CUSTOMERS_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    if (payload) {
      state.customersMap = new Map([...state.customersMap, ...payload])
    } else if (payload === null) {
      state.customersMap = new Map()
    }
  },

  [CustomersMutation.SET_CUSTOMERS_SEARCH_LIST](state, payload) {
    state.customersSearchList = payload || []
  },

  [CustomersMutation.SET_CUSTOMERS_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [CustomersMutation.REMOVE_CUSTOMER](state, payload) {
    if (state.customersMap?.has(payload)) {
      state.customersMap?.delete(payload)
    }
  },

  [CustomersMutation.SET_CUSTOMERS_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [CustomersAction.FETCH_CUSTOMERS_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchCustomersFromServer(payload.fetchSize, context.state.lastDocRef)
      context.commit(CustomersMutation.SET_CUSTOMERS_MAP, resp.customersMap)
      context.commit(CustomersMutation.SET_CUSTOMERS_LAST_DOCREF, resp.lastDocRef)
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [CustomersAction.FETCH_CUSTOMERS_ON_LOAD](context) {
    try {
      context.commit(CustomersMutation.SET_CUSTOMERS_LOADING, true)

      // Making customers map null on refresh, otherwise latest wont show up on top.
      context.commit(CustomersMutation.SET_CUSTOMERS_LAST_DOCREF, null)
      context.commit(CustomersMutation.SET_CUSTOMERS_MAP, null)

      await context.dispatch(CustomersAction.FETCH_CUSTOMERS_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
      })
    } catch (error) {
      notifyWarning(error, true)
    } finally {
      context.commit(CustomersMutation.SET_CUSTOMERS_LOADING, false)
    }
  },

  async [CustomersAction.LOAD_CUSTOMERS_SEARCH_BY_LISTENERS](context) {
    try {
      const unsubscribe = await fb.setupCustomersSearchListener(
        (customersSearch) => {
          context.commit(CustomersMutation.SET_CUSTOMERS_LOADING, true)
          context.commit(
            CustomersMutation.SET_CUSTOMERS_SEARCH_LIST,
            customersSearch.doc?.customerMapping,
          )
          context.commit(CustomersMutation.SET_CUSTOMERS_LOADING, false)
        },
        () => {
          context.commit(CustomersMutation.SET_CUSTOMERS_LOADING, false)
        },
      )
      context.commit(CustomersMutation.SET_CUSTOMERS_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      notifyWarning(error, true)
    }
  },

  async [CustomersAction.FETCH_CUSTOMERS_BY_IDLIST](context, payload) {
    try {
      const idList = payload?.idList // List of IDs ["","",""]
      if (idList) {
        const customersMapRes = await fb.fetchCustomersByDocIdList(idList)
        context.commit(CustomersMutation.SET_CUSTOMERS_MAP, customersMapRes)
        return [...customersMapRes.values()]
      }
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [CustomersAction.UNSUBSCRIBE_CUSTOMERS_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },

  async [CustomersAction.SET_OR_DELETE_CUSTOMER](context, payload) {
    try {
      const resp = await fb.setOrDeleteCustomer(payload)
      if (resp.result.isSuccess) {
        if (payload.action !== DocumentActions.DELETE) {
          notifySuccess(
            `Customer ${payload?.action?.toLowerCase()} successful`,
            `Customer ID is ${resp.responseData?.cid}`,
          )
          await context.dispatch(CustomersAction.FETCH_CUSTOMERS_BY_IDLIST, {
            idList: [resp.responseData?.id],
          })
        } else {
          context.commit(CustomersMutation.REMOVE_CUSTOMER, payload.id)
          notifySuccess('Customer Deleted.')
        }
      }
    } catch (error) {
      notifyWarning(error)
    }
  },

  async [CustomersAction.SET_OR_DELETE_REFISSUE](context, payload) {
    try {
      const resp = await fb.setOrDeleteRefIssue(payload)
      if (resp.result.isSuccess) {
        if (payload.action !== DocumentActions.DELETE) {
          notifySuccess(`Customer RefIssue ${payload?.action?.toLowerCase()} successful`)
        } else {
          // context.commit(CustomersMutation.REMOVE_CUSTOMER, payload.id)
          notifySuccess('Customer RefIssue Deleted.')
        }
      }
      return resp.result.isSuccess
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  async [CustomersAction.UPDATE_CUSTOMER_PHOTO](context, payload) {
    try {
      const url =
        payload?.action?.toLowerCase() === 'create'
          ? await fb.uploadCustomerProfilePhoto(payload?.photoFile, payload?.id)
          : null
      await fb.updateCustomerPhotoUrl(url, payload?.id)
      notifySuccess(`Customer Profile Update`, 'Changed customer profile picture.')
      return url
    } catch (error) {
      notifyWarning(error)
    }
  },

  async [CustomersAction.FETCH_CUSTOMER_PROFILE](context, payload) {
    try {
      const resp = await fb.fetchCustomerProfile(payload.id)
      return resp
    } catch (error) {
      notifyWarning(error, true)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
