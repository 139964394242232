import XLSX from 'xlsx'

export async function GetFileAsJson(result: string | ArrayBuffer | null, skipRows: number = 0) {
  const workbook = XLSX.read(result, {
    type: 'buffer',
    cellDates: true,
    dateNF: 'YYYY-MM-DD',
  })
  const wsname = workbook.SheetNames[0]
  const ws = workbook.Sheets[wsname]
  var jsonArray = XLSX.utils.sheet_to_json(ws, {
    // header: 1,
    raw: false, // false - to parse numbers as strings // PhoneNumbers and Pincodes shall be string. Only those values should be number on which we will perform numeric ops.
    defval: null, // Using null to set the same in firestore docs. Also if defval is not passed, the other keys wont be present so table will come as empty
    range: skipRows, // if range = 1, skip first row
    // dateNF: 'YYYY"-"MM"-"DD', // Not working, please test. Standard format - yyyy-mm-dd
  })

  return jsonArray
}
