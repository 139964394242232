import * as fb from '../..'
import * as common from '../../common'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { AppConfigSettingsDocument } from '@/types/firebaseCollectionContracts/AppConfigSettingsDocument'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseDocuments } from '@/types/enums/FirebaseDocuments'
import { DashboardInsightsDocument } from '@/types/firebaseCollectionContracts/DashboardInsightsDocument'
import { AddonFormData } from '@/types/appcontracts/AddonFormData'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { AppConfigSecurityDocument } from '@/types/firebaseCollectionContracts/AppConfigSecurityDocument'

export async function setupSearchAggregatesListener(
  callbackOnSuccess: (searchDocList: IAppDocument<unknown>[]) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db.collection(FirebaseCollections.SearchAggregates).onSnapshot(
      (querySnapshot) => {
        const searchDocList: IAppDocument<unknown>[] = []
        querySnapshot.forEach((docData) => {
          const searchDoc: IAppDocument<unknown> = {
            id: docData.id,
            doc: docData.data() as unknown,
          }

          searchDocList.push(searchDoc)
        })
        // If all data is deleted, it wont update the store as id wont be there so switch case wont work
        callbackOnSuccess(searchDocList)
      },
      () => {
        callbackOnError()
      },
    )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setupAppConfigSettingsListener(
  callbackOnSuccess: (appConfigSettings: IAppDocument<AppConfigSettingsDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.AppConfigurations)
      .doc(FirebaseDocuments.AppConfigSettings)
      .onSnapshot(
        async (querySnapshot) => {
          if (!querySnapshot.exists) {
            await common.callFunction<string>(FirebaseFunctions.InitDocumentsSetup)
          }
          const appConfigSettings: IAppDocument<AppConfigSettingsDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as AppConfigSettingsDocument,
          }

          callbackOnSuccess(appConfigSettings)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setupDashboardInsightsListener(
  callbackOnSuccess: (dashboardInsights: IAppDocument<DashboardInsightsDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.Dashboard)
      .doc(FirebaseDocuments.DashboardInsights)
      .onSnapshot(
        async (querySnapshot) => {
          const dashboardInsights: IAppDocument<DashboardInsightsDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as DashboardInsightsDocument,
          }

          callbackOnSuccess(dashboardInsights)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setupAppConfigSecurityListener(
  callbackOnSuccess: (appConfigSecurity: IAppDocument<AppConfigSecurityDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.AppConfigurations)
      .doc(FirebaseDocuments.AppConfigSecurity)
      .onSnapshot(
        async (querySnapshot) => {
          const appConfigSecurity: IAppDocument<AppConfigSecurityDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as AppConfigSecurityDocument,
          }

          callbackOnSuccess(appConfigSecurity)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setOrDeleteServiceCharge(data: DocumentOperation<AddonFormData>) {
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteServiceCharge, data)
  return resp
}

export async function setOrDeleteTax(data: DocumentOperation<AddonFormData>) {
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteTax, data)
  return resp
}
