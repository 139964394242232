import { createApp } from 'vue'
import {
  Skeleton,
  Avatar,
  Descriptions,
  Popconfirm,
  Modal,
  Divider,
  Button,
  Breadcrumb,
  Layout,
  Table,
  Icon,
  Radio,
  Dropdown,
  Menu,
  Input,
  Badge,
  Form,
  Tooltip,
  Select,
  Tag,
  Spin,
  Alert,
  Checkbox,
  Tabs,
  Pagination,
  Upload,
  InputNumber,
  Drawer,
  DatePicker,
  AutoComplete,
  Collapse,
  Empty,
  ConfigProvider,
  Popover,
  Switch,
  List,
  Col,
  Row,
  Affix,
} from 'ant-design-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './locale'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  // .use(Antd)
  .use(PerfectScrollbar)

app.use(Skeleton)
app.use(Avatar)
app.use(Descriptions)
app.use(Popconfirm)
app.use(Popover)
app.use(Switch)
app.use(Modal)
app.use(Divider)
app.use(ConfigProvider)
app.use(Button)
app.use(Breadcrumb)
app.use(Layout)
app.use(Table)
app.use(Icon)
app.use(Radio)
app.use(Dropdown)
app.use(Menu)
app.use(Input)
app.use(Badge)
app.use(Form)
app.use(Tooltip)
app.use(Select)
app.use(Tag)
app.use(Spin)
app.use(Alert)
app.use(Checkbox)
app.use(Tabs)
app.use(Pagination)
app.use(Upload)
app.use(InputNumber)
app.use(Drawer)
app.use(DatePicker)
app.use(AutoComplete)
app.use(Collapse)
app.use(List)
app.use(Row)
app.use(Col)
app.use(Affix)
app.use(Empty)
app.mount('#app')
