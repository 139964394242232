import * as fb from '../..'
import * as common from '../../common'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { VendorZoneFormData } from '@/types/appcontracts/VendorZoneFormData'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { VendorZonesDocument } from '@/types/firebaseCollectionContracts/VendorZonesDocument'
import { firebaseQueryFieldPaths } from '@/services/helpers/constants'
import { VendorZonesSearchDocument } from '@/types/firebaseCollectionContracts/VendorZonesSearchDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'

export async function setupZonesSearchListener(
  callbackOnSuccess: (zonesSearchDoc: IAppDocument<VendorZonesSearchDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.SearchAggregates)
      .doc(FirebaseCollections.VendorZones)
      .onSnapshot(
        (querySnapshot) => {
          const zonesSearchDoc: IAppDocument<VendorZonesSearchDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as VendorZonesSearchDocument,
          }

          callbackOnSuccess(zonesSearchDoc)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setOrDeleteZone(data: DocumentOperation<VendorZoneFormData>) {
  data.messageType = FirebaseCollections.VendorZones
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteEntity, data)
  return resp
}

/**
 * Fetch Zones from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchZonesFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<VendorZonesDocument>(
    FirebaseCollections.VendorZones,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
  )

  return resp
}
