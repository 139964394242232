import * as fb from '../..'
import * as common from '../../common'

import { DataImportContract } from '@/types/appContracts/DataImportContract'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'

export async function importEntityData(importData: DataImportContract) {
  const res = await common.callFunction<string>(FirebaseFunctions.ImportEntityData, importData)
  return res
}

export async function downloadEntitySample(entity: string) {
  const entityRef = fb.storageImportRef.child(`${entity}.xlsx`)
  const downloadUrl = await entityRef.getDownloadURL()
  return downloadUrl
}
