<template>
  <div :class="$style.topbar">
    <div class="mr-4">
      <vb-fav-pages />
    </div>
    <!-- <div>
      <vb-search />
    </div> -->
    <div class="mr-auto mr-md-1">
      <power-theme-switcher />
    </div>
    <div class="mr-3 d-sm-block ml-auto">
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
// import VbSearch from './Search'
import VbUserMenu from './UserMenu'
import VbFavPages from './FavPages'
import PowerThemeSwitcher from './ThemeSwitcher'

export default {
  components: {
    // VbSearch,
    VbUserMenu,
    VbFavPages,
    PowerThemeSwitcher,
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
