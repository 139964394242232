import * as fb from '../..'
import * as common from '../../common'
import chunk from 'lodash/chunk'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { firebaseQueryFieldPaths } from '@/services/helpers/constants'
import { RateFormData } from '@/types/appcontracts/RateFormData'
import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'

export async function setOrDeleteShippingRate(data: DocumentOperation<RateFormData>) {
  data.messageType = FirebaseCollections.ShippingRates
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteEntity, data)
  return resp
}

/**
 * Fetch Zones from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchRatesFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<ShippingRatesDocument>(
    FirebaseCollections.ShippingRates,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
  )

  return resp
}

export async function fetchRatesByDocIdList(idList: Array<string>) {
  const chunks = chunk(idList, 10)
  const ratesMap = new Map<string, IAppDocument<ShippingRatesDocument>>()

  const promises = chunks.map((arr) => {
    return fb.db
      .collection(FirebaseCollections.ShippingRates)
      .where(fb.dbFieldPath.documentId(), 'in', arr)
      .get()
  })
  const resp = await Promise.all(promises)
  resp.map((rec) => {
    rec.forEach((doc) =>
      ratesMap.set(doc.id, { id: doc.id, doc: doc.data() as ShippingRatesDocument }),
    )
  })

  return ratesMap
}

export async function fetchRateByDocId(docId: string) {
  if (!docId) {
    return null
  }
  const ratesMap = new Map<string, IAppDocument<ShippingRatesDocument>>()
  const resp = await fb.db
    .collection(FirebaseCollections.ShippingRates)
    .doc(docId)
    .get()

  ratesMap.set(docId, { id: docId, doc: resp.data() as ShippingRatesDocument })
  return ratesMap
}
