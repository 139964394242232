import * as fb from '../..'
import * as common from '../../common'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { ConsignmentFormData } from '@/types/appcontracts/ConsignmentFormData'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { firebaseQueryFieldPaths } from '@/services/helpers/constants'
import { IInvoiceConsignmentQuery } from '@/types/interfaces/IInvoiceConsignmentQuery'
import dayjs from 'dayjs'
import { ShipmmentTrackContract } from '@/types/appcontracts/ShipmentTrackContract'
import { IShipmentStatusTrack } from '@/types/interfaces/IShipmentStatusTrack'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'

export async function setOrDeleteConsignment(data: DocumentOperation<ConsignmentFormData>) {
  data.messageType = FirebaseCollections.Consignments
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteEntity, data)
  return resp
}

/**
 * Fetch Consignments from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchConsignmentsFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
  filters: IFilterWhereQuery[] | null | undefined,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<ConsignmentsDocument>(
    FirebaseCollections.Consignments,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
    'desc',
    filters,
  )

  resp.data.forEach((appDoc) => formatDoc(appDoc.doc))
  return resp
}

export async function fetchConsignmentData(id: string) {
  const dataResp = await fb.db
    .collection(FirebaseCollections.Consignments)
    .doc(id)
    .get()

  const data = formatDoc(dataResp.data() as ConsignmentsDocument)
  return data
}

export async function fetchConsignmentDataByRfn(rfn: string) {
  // TODO: Add to state ?
  const resp = await fb.db
    .collection(FirebaseCollections.Consignments)
    .where('rfn', '==', rfn)
    .get()
  const docs = resp.docs.map((x) => formatDoc(x.data() as ConsignmentsDocument))
  return docs
}

export async function fetchConsignmentDataByAwbOrRfn(trackId: string) {
  // TODO: Add to state ?
  let resp = await fb.db
    .collection(FirebaseCollections.Consignments)
    .where('awb', '==', trackId)
    .get()
  if (resp.empty) {
    resp = await fb.db
      .collection(FirebaseCollections.Consignments)
      .where('rfn', '==', trackId)
      .get()
  }
  if (resp.empty) {
    return null
  }
  const docs = resp.docs.map((x) => formatDoc(x.data() as ConsignmentsDocument))
  return docs
}

export async function fetchConsignmentDataForInvoice(queryData: IInvoiceConsignmentQuery | null) {
  if (!queryData || !queryData.customerId || !queryData.fromDate || !queryData.toDate) {
    return null
  }
  const toDate = dayjs(queryData?.toDate)
    .add(1, 'day')
    .subtract(1, 'minute')
    .toDate()
  const resp = await fb.db
    .collection(FirebaseCollections.Consignments)
    .where('customerId', '==', queryData.customerId)
    .where('invoiceId', '==', null)
    .where('paymentMode', '==', null)
    .where('isVendorPresent', '==', true)
    .where('bookingDate', '>=', queryData?.fromDate)
    .where('bookingDate', '<=', toDate)
    .limit(400)
    .get()

  const docs = resp.docs.map((x) => formatDoc(x.data() as ConsignmentsDocument))
  return docs
}

export async function getShipmentTracking(data: ShipmmentTrackContract) {
  const resp = await common.callFunction<IShipmentStatusTrack | null>(
    FirebaseFunctions.GetShipmentTracking,
    data,
  )
  return resp
}

function formatDoc(doc: ConsignmentsDocument | null | undefined) {
  if (doc) {
    doc.bookingDate = common.convertFirestoreTimestampToDate(doc?.bookingDate)
    doc.deliveryDate = common.convertFirestoreTimestampToDate(doc?.deliveryDate)
  }
  return doc
}
