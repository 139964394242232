
import { watch, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import english from '@/locales/en-US'

const locales = {
  'en-US': english,
}

export default {
  name: 'Localization',
  setup() {
    const store = useStore()
    const { locale } = useI18n()
    const settingsLocale = computed(() => store.state.settings.locale)
    const localeAntd = ref(locales['en-US'].localeAntd)

    // set locale from settings on app load
    locale.value = settingsLocale.value

    // watch locale settings change and apply
    watch(settingsLocale, (settingsLocale) => {
      locale.value = settingsLocale
      localeAntd.value = locales['en-US'].localeAntd
    })

    return {
      localeAntd,
    }
  },
}
