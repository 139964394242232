<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <a
        href="https://visualbuilder.cloud"
        target="_blank"
        rel="noopener noreferrer"
        :class="$style.logo"
      >
        <!-- <img src="resources/images/logo.svg" class="mr-2" alt="Power Media" /> -->
        <strong class="mr-2">{{ settings.logo }} Systems and Services</strong>
      </a>
      <br />
      <p class="mb-0">
        Copyright © 2021
        <a href="#" target="_blank" rel="noopener noreferrer">
          Broken Theory
        </a>
        |
        <a href="#" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
