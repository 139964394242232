import * as fb from '../..'
import * as common from '../../common'
import chunk from 'lodash/chunk'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { firebaseQueryFieldPaths } from '@/services/helpers/constants'
import { CompanyFormData } from '@/types/appcontracts/CompanyFormData'
import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { CompaniesSearchDocument } from '@/types/firebaseCollectionContracts/CompaniesSearchDocument'

export async function setupCompaniesListener(
  callbackOnSuccess: (companiesSearchDoc: IAppDocument<CompaniesSearchDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.SearchAggregates)
      .doc(FirebaseCollections.Companies)
      .onSnapshot(
        (querySnapshot) => {
          const companiesSearchDoc: IAppDocument<CompaniesSearchDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as CompaniesSearchDocument,
          }

          callbackOnSuccess(companiesSearchDoc)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setOrDeleteCompany(data: DocumentOperation<CompanyFormData>) {
  data.messageType = FirebaseCollections.Companies
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteEntity, data)
  return resp
}

/**
 * Fetch Zones from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchCompaniesFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<CompaniesDocument>(
    FirebaseCollections.Companies,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
  )

  return resp
}

export async function fetchCompaniesByDocIdList(idList: Array<string>) {
  const chunks = chunk(idList, 10)
  const companiesMap = new Map<string, IAppDocument<CompaniesDocument>>()

  const promises = chunks.map((arr) => {
    return fb.db
      .collection(FirebaseCollections.Companies)
      .where(fb.dbFieldPath.documentId(), 'in', arr)
      .get()
  })
  const resp = await Promise.all(promises)
  resp.map((rec) => {
    rec.forEach((doc) =>
      companiesMap.set(doc.id, { id: doc.id, doc: doc.data() as CompaniesDocument }),
    )
  })

  return companiesMap
}

export async function fetchCompanyByDocId(docId: string) {
  if (!docId) {
    return null
  }
  const companiesMap = new Map<string, IAppDocument<CompaniesDocument>>()
  const resp = await fb.db
    .collection(FirebaseCollections.Companies)
    .doc(docId)
    .get()

  companiesMap.set(docId, { id: docId, doc: resp.data() as CompaniesDocument })
  return companiesMap
}
