import * as fb from '../../../services/firebase/modules/zones'
import { notifySuccess, notifyWarning } from '../../helpers'
import { ZonesAction } from '../../actions.type'
import { ZonesMutation } from '@/store/mutations.type'

const state = () => ({
  zonesIdMap: new Map(),
  zonesLoading: true,
  unsubscribeListenersList: [],
  fetchSize: 15,
  zonesSearchList: [],
  lastDocRef: null,
})

const getters = {
  zonesExists: (state) => state.zonesSearchList.length != 0,
  zoneList: (state) => {
    if (state.zonesIdMap) {
      return [...state.zonesIdMap.values()]
    } else {
      return []
    }
  },
  zonesNameList: (state) => state.zonesSearchList?.map((x) => x.name),
}

const mutations = {
  [ZonesMutation.SET_ZONES_LOADING](state, payload) {
    state.zonesLoading = payload
  },

  [ZonesMutation.SET_ZONES_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.zonesIdMap = payload ? new Map([...state.zonesIdMap, ...payload]) : new Map()
  },

  [ZonesMutation.SET_ZONES_SEARCH_LIST](state, payload) {
    state.zonesSearchList = payload || []
  },

  [ZonesMutation.SET_ZONES_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [ZonesMutation.SET_ZONES_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [ZonesAction.SET_OR_DELETE_ZONE](context, payload) {
    // TODO: Check payload if op is create
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteZone(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Service Zone ${payload?.action?.toLowerCase()} successful`)
        // await context.dispatch(ZonesAction.FETCH_ZONES_BY_IDLIST, {
        //   idList: [resp.responseData?.id],
        // })
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  // async [ZonesAction.UPDATE_ZONE](context, payload) {
  //   // TODO: Use payload data for notification
  //   try {
  //     const resp = await fb.setOrDeleteZone(payload)
  //     if (resp.result.isSuccess) {
  //       notifySuccess(`Updated Vendor Service`, `Refresh to see data`)
  //     }
  //   } catch (error) {
  //     notifyWarning(error)
  //   }
  // },

  async [ZonesAction.FETCH_ZONES_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchZonesFromServer(payload.fetchSize, context.state.lastDocRef)
      context.commit(ZonesMutation.SET_ZONES_MAP, resp.data)
      context.commit(ZonesMutation.SET_ZONES_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [ZonesAction.FETCH_ZONES_ON_LOAD](context) {
    try {
      context.commit(ZonesMutation.SET_ZONES_LOADING, true)

      // Making zones map null on refresh, otherwise latest wont show up on top.
      context.commit(ZonesMutation.SET_ZONES_LAST_DOCREF, null)
      context.commit(ZonesMutation.SET_ZONES_MAP, null)

      await context.dispatch(ZonesAction.FETCH_ZONES_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
      })
    } catch (error) {
      notifyWarning(error, true)
    } finally {
      context.commit(ZonesMutation.SET_ZONES_LOADING, false)
    }
  },

  async [ZonesAction.LOAD_ZONES_SEARCH_BY_LISTENERS](context) {
    try {
      const unsubscribe = await fb.setupZonesSearchListener(
        (zonesSearchDoc) => {
          context.commit(ZonesMutation.SET_ZONES_LOADING, true)
          context.commit(ZonesMutation.SET_ZONES_SEARCH_LIST, zonesSearchDoc.doc?.zoneMapping)
          context.commit(ZonesMutation.SET_ZONES_LOADING, false)
        },
        () => {
          context.commit(ZonesMutation.SET_ZONES_LOADING, false)
        },
      )
      context.commit(ZonesMutation.SET_ZONES_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      notifyWarning(error, true)
    }
  },

  async [ZonesAction.UNSUBSCRIBE_ZONES_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
