import * as fb from '../..'
import { callFunction, uploadToStorageAndGetLink } from '../../common'
import { FirebaseFunctions } from '../../../../types/enums/FirebaseFunctions'
import { FirebaseUser, AppUser } from './users.types'
import { AppUserData } from '@/types/appContracts/AppUserData'
import { formatStringParam } from '@/services/helpers'
import { firebaseStoragePaths } from '@/services/helpers/constants'

export async function login(email: string, password: string) {
  // Returning obj in the format {user, error}
  try {
    const userCred = await fb.firebaseAuth.signInWithEmailAndPassword(email, password)
    const isRoleAssigned = await new FirebaseUser().IsRoleAssigned(userCred.user)
    if (!isRoleAssigned) {
      return {
        success: false,
        error: {
          code: 'Validation Pending',
          message: 'You need to be validated by Power Media in order to login.',
        },
      }
    }
    if (!userCred.user?.emailVerified) {
      await TriggerEmailVerification()
      return {
        success: false,
        error: {
          code: 'Verify Email',
          message: 'You need to verify your email id in order to login.',
        },
      }
    }
    return {
      success: true,
      error: null,
    }
  } catch (error) {
    return { success: false, error }
  }
}

export async function register(email: string, password: string, name: string) {
  try {
    const userCred = await fb.firebaseAuth.createUserWithEmailAndPassword(email, password)
    await userCred.user?.updateProfile({ displayName: name })
    // TODO: Send verification email based on some setting
    await userCred.user?.sendEmailVerification()
    // TODO: create user using cloud func
    // TODO: Add recaptcha
    return { success: true, error: null }
  } catch (error) {
    return { success: false, error }
  }
}

export async function setupAuthListener(callback: (user: AppUser) => Promise<void>) {
  // Whenever the app is loaded, auth listener is setup.
  return new Promise((resolve, reject) => {
    fb.firebaseAuth.onAuthStateChanged(async (user) => {
      // TODO: Setup global FB listeners?
      let fbUser = new FirebaseUser()
      await fbUser.SetupAndVerifyUser(user)
      await callback(fbUser.appUser)
      resolve(true)
    }, reject)
  })
}

export async function logout() {
  return fb.firebaseAuth.signOut().then(() => true)
}

export async function TriggerEmailVerification() {
  // Add checks and conditions if required.
  // TODO: Add redireection uri using ActionCodeSettings
  const currUser = fb.firebaseAuth.currentUser
  if (currUser && !currUser.emailVerified) {
    await currUser.sendEmailVerification()
  }
}

export async function TriggerPasswordReset(email: string) {
  if (email) {
    await fb.firebaseAuth.sendPasswordResetEmail(email)

    // TODO: Temporary. Added for local emulator.
    await TriggerEmailVerification()
  }
}

export async function UpdateUserName(name: string) {
  if (name) {
    await fb.firebaseAuth.currentUser?.updateProfile({
      displayName: name,
    })
  }
}

export async function UpdateUserPhotoUrl(url: string) {
  await fb.firebaseAuth.currentUser?.updateProfile({
    photoURL: url ?? null,
  })
}

export async function UploadUserPhoto(file: File, uid: string): Promise<String | null> {
  // Check if its better to get uid from auth.currentUser
  if (uid) {
    const path = formatStringParam(firebaseStoragePaths.userProfilePhoto, { id: uid })
    const url = await uploadToStorageAndGetLink(file, path)
    return url
  }

  return null
}

export async function GetAppUsers() {
  const res = await callFunction<AppUserData[]>(FirebaseFunctions.GetAppUsers)
  return res.responseData || []
}

export async function SetAppUserRole(data: unknown) {
  const res = await callFunction<Boolean>(FirebaseFunctions.SetAppUserRole, data)
  return res
}
