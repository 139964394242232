export default [
  {
    path: '/purchase/vendors',
    meta: { title: 'Vendors' },
    name: 'vendors',
    component: () => import('../../views/purchase/vendors'),
  },
  {
    path: '/purchase/vendors/new',
    meta: { title: 'New Vendor' },
    name: 'newVendor',
    component: () => import('../../views/purchase/vendors/new'),
  },
  {
    path: '/purchase/vendors/profile',
    meta: { title: 'Profile' },
    name: 'vendorProfile',
    component: () => import('../../views/purchase/vendors/profile'),
  },
  {
    path: '/purchase/vendors/:id/edit',
    meta: { title: 'Edit Vendor' },
    name: 'editVendor',
    component: () => import('../../views/purchase/vendors/new'),
  },
  // {
  //   path: '/purchase/vendors/services/new',
  //   meta: { title: 'New Service' },
  //   component: () => import('../../views/purchase/vendors/services/new'),
  // },
]
