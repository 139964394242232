export enum FirebaseFunctions {
  GetAppUsers = 'getAppUsers',
  SetAppUserRole = 'setAppUserRole',
  AddCustomer = 'addCustomer',
  ImportEntityData = 'importEntityData',
  SetOrDeleteEntity = 'setOrDeleteEntity',
  // SetOrDeleteCustomer = 'setOrDeleteCustomer',
  // SetOrDeleteVendor = 'setOrDeleteVendor',
  // SetOrDeleteVendorService = 'setOrDeleteVendorService',
  // SetOrDeleteVendorZone = 'setOrDeleteVendorZone',
  GetCountryStateData = 'getCountryStateData',
  // SetOrDeleteShippingRate = 'setOrDeleteShippingRate',
  // SetOrDeleteConsignment = 'setOrDeleteConsignment',
  // SetOrDeleteCompany = 'setOrDeleteCompany',
  // SetOrDeleteInvoice = 'setOrDeleteInvoice',
  InitDocumentsSetup = 'initDocumentsSetup',
  DownloadStorageFile = 'downloadStorageFile',
  SetOrDeleteServiceCharge = 'setOrDeleteServiceCharge',
  SetOrDeleteTax = 'setOrDeleteTax',
  GetShipmentTracking = 'getShipmentTracking',
}
