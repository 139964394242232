import * as fb from '../..'
import * as common from '../../common'
import chunk from 'lodash/chunk'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { firebaseQueryFieldPaths } from '@/services/helpers/constants'
import { InvoiceFormData } from '@/types/appcontracts/InvoiceFormData'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { InvoiceStatusTypes } from '@/types/enums/InvoiceStatusTypes'
import { IInvoiceResponse } from '@/types/interfaces/IInvoiceResponse'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'

// export async function setupInvoicesListener(
//   callbackOnSuccess: (invoicesSearchDoc: IAppDocument<InvoicesSearchDocument>) => void,
//   callbackOnError: () => void,
// ) {
//   try {
//     const unsubscribe = fb.db
//       .collection(FirebaseCollections.SearchAggregates)
//       .doc(FirebaseCollections.Invoices)
//       .onSnapshot(
//         (querySnapshot) => {
//           const invoicesSearchDoc: IAppDocument<InvoicesSearchDocument> = {
//             id: querySnapshot.id,
//             doc: querySnapshot.data() as InvoicesSearchDocument,
//           }

//           callbackOnSuccess(invoicesSearchDoc)
//         },
//         () => {
//           callbackOnError()
//         },
//       )
//     return unsubscribe
//   } catch (error) {
//     return false
//   }
// }

export async function setOrDeleteInvoice(data: DocumentOperation<InvoiceFormData>) {
  data.messageType = FirebaseCollections.Invoices
  const resp = await common.callFunction<IInvoiceResponse>(
    FirebaseFunctions.SetOrDeleteEntity,
    data,
  )
  return resp
}

/**
 * Fetch Zones from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchInvoicesFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
  filters: IFilterWhereQuery[] | null | undefined,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<InvoicesDocument>(
    FirebaseCollections.Invoices,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
    'desc',
    filters,
  )

  resp.data.forEach((appDoc) => formatDoc(appDoc.doc))

  return resp
}

export async function fetchInvoicesByDocIdList(idList: Array<string>) {
  const chunks = chunk(idList, 10)
  const invoicesMap = new Map<string, IAppDocument<InvoicesDocument>>()

  const promises = chunks.map((arr) => {
    return fb.db
      .collection(FirebaseCollections.Invoices)
      .where(fb.dbFieldPath.documentId(), 'in', arr)
      .get()
  })
  const resp = await Promise.all(promises)
  resp.map((rec) => {
    rec.forEach((doc) =>
      invoicesMap.set(doc.id, { id: doc.id, doc: doc.data() as InvoicesDocument }),
    )
  })

  invoicesMap.forEach((appDoc) => formatDoc(appDoc.doc))

  return invoicesMap
}

export async function fetchInvoiceByDocId(docId: string) {
  if (!docId) {
    return null
  }
  let invoiceDoc: IAppDocument<InvoicesDocument>
  const resp = await fb.db
    .collection(FirebaseCollections.Invoices)
    .doc(docId)
    .get()

  invoiceDoc = { id: docId, doc: formatDoc(resp.data() as InvoicesDocument) }
  return invoiceDoc
}

export async function fetchInvoiceByInvId(invId: string) {
  if (!invId) {
    return null
  }
  let invoiceDoc: IAppDocument<InvoicesDocument>
  const resp = await fb.db
    .collection(FirebaseCollections.Invoices)
    .where('invid', '==', invId)
    .get()
  const doc = resp.docs?.[0]
  invoiceDoc = { id: doc?.id, doc: formatDoc(doc?.data() as InvoicesDocument) }
  return invoiceDoc
}

export async function updateInvoiceStatus(
  invDocId: string | null,
  status: InvoiceStatusTypes,
  amount: number,
) {
  if (invDocId && status) {
    const docRef = fb.db.collection(FirebaseCollections.Invoices).doc(invDocId)
    const modifiedByData = common.getModifiedByData()
    const bln = status === InvoiceStatusTypes.Paid ? 0 : amount
    const updateData = {
      invoiceStatus: status,
      balanceDue: bln,
      ...modifiedByData,
    }

    await docRef.update({ ...updateData })
    return bln
  }
}

export async function downloadInvoice(custId: string, invoiceId: string) {
  // const entityRef = fb.storageCustomersRef.child(`${custId}/invoices/${invoiceId}.pdf`)
  // const downloadUrl = await entityRef.getDownloadURL()
  // return downloadUrl
  const invid = invoiceId.replace(/\//g, '-')
  const path = `customers/${custId}/invoices/${invid}.pdf`
  const resp = await common.callFunction<string>(FirebaseFunctions.DownloadStorageFile, path)
  return resp
}

function formatDoc(doc: InvoicesDocument) {
  if (doc) {
    doc.invoiceDate = common.convertFirestoreTimestampToDate(doc?.invoiceDate)
    doc.dueDate = common.convertFirestoreTimestampToDate(doc?.dueDate)
  }
  return doc
}
