import { createStore } from 'vuex'
import user from './modules/users'
import customers from './modules/customers'
import vendors from './modules/vendors'
import vendorServices from './modules/vendorServices'
import tools from './modules/tools'
import zones from './modules/zones'
import consignments from './modules/consignments'
import rates from './modules/rates'
import companies from './modules/companies'
import invoices from './modules/invoices'
import settings from './settings'
import appGlobals from './appGlobals'

export default createStore({
  modules: {
    user, // TODO: Update user to users ?
    settings,
    customers,
    vendors,
    vendorServices,
    tools,
    zones,
    rates,
    companies,
    consignments,
    invoices,
    appGlobals,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
})
