export const UsersMutation = {
  SET_USER_LOADING: 'setUserLoading',
  SET_USER_PROPERTIES: 'setUserProperties',
  SET_USER_AUTH: 'setUserAuth',
}

export const CustomersMutation = {
  SET_CUSTOMERS_MAP: 'setCustomersMap',
  SET_CUSTOMERS_LAST_DOCREF: 'setCustomersLastDocRef',
  SET_CUSTOMERS_LOADING: 'setCustomersLoading',
  REMOVE_CUSTOMER: 'removeCustomer',
  SET_CUSTOMERS_UNSUBSCRIBE_LISTENERS: 'setCustomersUnsubscribeListerners',
  SET_CUSTOMERS_SEARCH_LIST: 'setCustomersSearchList',
}

export const VendorsMutation = {
  SET_VENDORS_MAP: 'setVendorsMap',
  SET_VENDORS_LAST_DOCREF: 'setVendorsLastDocRef',
  SET_VENDORS_LOADING: 'setVendorsLoading',
  REMOVE_VENDOR: 'removeVendor',
  SET_VENDORS_UNSUBSCRIBE_LISTENERS: 'setVendorsUnsubscribeListerners',
  SET_VENDORS_SEARCH_LIST: 'setVendorsSearchList',
}

export const VendorServicesMutation = {
  SET_VENDOR_SERVICES_MAP: 'setVendorServicesMap',
  SET_VENDOR_SERVICES_LAST_DOCREF: 'setVendorServicesLastDocRef',
  REMOVE_VENDOR_SERVICE: 'removeVendorService',
  SET_VENDOR_SERVICES_LOADING: 'setVendorServicesLoading',
  SET_VENDOR_SERVICES_UNSUBSCRIBE_LISTENERS: 'setVendorServicesUnsubscribeListerners',
  SET_VENDOR_SERVICES_SEARCH_LIST: 'setVendorServicesSearchList',
}

export const ZonesMutation = {
  SET_ZONES_MAP: 'setZonesMap',
  SET_ZONES_LAST_DOCREF: 'setZonesLastDocRef',
  SET_ZONES_LOADING: 'setZonesLoading',
  SET_ZONES_UNSUBSCRIBE_LISTENERS: 'setZonesUnsubscribeListerners',
  SET_ZONES_SEARCH_LIST: 'setZonesSearchList',
}

export const ConsignmentsMutation = {
  SET_CONSIGNMENTS_MAP: 'setConsignmentsMap',
  SET_CONSIGNMENTS_LAST_DOCREF: 'setConsignmentsLastDocRef',
  REMOVE_CONSIGNMENT: 'removeConsignment',
  SET_CONSIGNMENTS_LOADING: 'setConsignmentsLoading',
  SET_CONSIGNMENTS_UNSUBSCRIBE_LISTENERS: 'setConsignmentsUnsubscribeListerners',
}

export const RatesMutation = {
  SET_SHIPPING_RATES_MAP: 'setShippingRatesMap',
  REMOVE_SHIPPING_RATE: 'removeShippingRate',
  SET_SHIPPING_RATES_LAST_DOCREF: 'setShippingRatesLastDocRef',
  SET_SHIPPING_RATES_LOADING: 'setShippingRatesLoading',
}

export const CompaniesMutation = {
  SET_COMPANIES_MAP: 'setCompaniesMap',
  SET_COMPANIES_LAST_DOCREF: 'setCompaniesLastDocRef',
  SET_COMPANIES_LOADING: 'setCompaniesLoading',
  SET_COMPANIES_UNSUBSCRIBE_LISTENERS: 'setCompaniesUnsubscribeListerners',
  SET_COMPANIES_SEARCH_LIST: 'setCompaniesSearchList',
}

export const InvoicesMutation = {
  SET_INVOICES_MAP: 'setInvoicesMap',
  SET_INVOICES_LAST_DOCREF: 'setInvoicesLastDocRef',
  SET_INVOICES_LOADING: 'setInvoicesLoading',
  SET_INVOICE_STATUS: 'setInvoiceStatus',
  REMOVE_INVOICE: 'removeInvoice',
  SET_INVOICES_UNSUBSCRIBE_LISTENERS: 'setInvoicesUnsubscribeListerners',
  SET_INVOICES_SEARCH_LIST: 'setInvoicesSearchList',
}

export const SettingsMutation = {
  SET_COUNTRIES: 'setCountries',
  SET_STATES: 'setStates',
  SET_CITIES: 'setCities',
}

export const GlobalMutation = {
  SET_GlOBAL_SETUP: 'setGlobalSetup',
  SET_APPCONFIG_SETTNGS: 'setAppConfigSettings',
  SET_APPCONFIG_SECURITY: 'setAppConfigSecurity',
  SET_DASHBOARD_INSIGHTS: 'setDashboardInsights',
  SET_GLOBAL_UNSUBSCRIBE_LISTENERS: 'setGlobalUnsubscribeListerners',
}
