import * as xlsx from './xlsx'

export async function ReadFileAsJson(file: any) {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    try {
      // async op
      reader.readAsArrayBuffer(file)

      // TODO: Add header based validation then pass ??
      reader.onload = async (e) => {
        const result = e.target?.result ?? null

        const jsonArray = await xlsx.GetFileAsJson(result)

        resolve(jsonArray)
      }
    } catch (error) {
      reject(error)
    }
  })
}
