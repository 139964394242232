export default [
  {
    path: '/sales/customers',
    meta: { title: 'Customers' },
    name: 'customers',
    component: () => import('../../views/sales/customers'),
  },
  {
    path: '/sales/customers/new',
    name: 'newCustomer',
    meta: { title: 'New Customer' },
    component: () => import('../../views/sales/customers/new'),
  },
  {
    path: '/sales/customers/:id/edit',
    meta: { title: 'Edit Customer' },
    name: 'editCustomer',
    component: () => import('../../views/sales/customers/edit'),
  },
  {
    path: '/sales/customers/:id/profile',
    meta: { title: 'Customer Profile' },
    name: 'customerProfile',
    component: () => import('../../views/sales/customers/profile'),
  },
  {
    path: '/sales/customers/issues/new',
    name: 'newCustomerIssue',
    meta: { title: 'Reference Issue' },
    component: () => import('../../views/sales/issues/new'),
  },
]
