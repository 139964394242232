import * as fileio from '../../../services/file-io'
import * as fb from '../../../services/firebase/modules/tools'
import { notification } from 'ant-design-vue'

import { ToolsAction } from '../../actions.type'
import { EntityTypes } from '@/types/enums/EntityTypes'
import {convertConsignmentImportDataToNumeric} from '@/services/helpers/index'

const state = () => ({})

const getters = {}

const mutations = {}

const actions = {
  async [ToolsAction.READ_IMPORT_FILE](context, file) {
    try {
      const jsonArray = await fileio.ReadFileAsJson(file)
      return jsonArray
    } catch (error) {
      console.log('Imp Read Err: ', error)
      return null
    }
  },

  [ToolsAction.GET_SUPPORTED_ENTITIES]() {
    return [
      EntityTypes.Customers,
      EntityTypes.Vendors,
      EntityTypes.Zones,
      EntityTypes.VendorServices,
      EntityTypes.Consignments,
    ]
  },

  async [ToolsAction.IMPORT_ENTITY_DATA](context, { importData }) {
    try {
      switch (importData.entity) {
        case EntityTypes.Customers: {
          // Convert OpeningBalance to numeric.
          importData.data.forEach((el) => {
            el.openingBalance = parseFloat(el.openingBalance) || 0
          })
          const resp = await fb.importEntityData(importData)
          return resp
        }

        case EntityTypes.Vendors: {
          // Check is alias is unique
          const aliasList = context.rootGetters['vendors/vendorsAliasList']
          if (importData.data?.some((x) => aliasList?.includes(x?.alias))) {
            throw new Error('Aliases already exists. Please try again with all unique aliases.')
          }
          const resp = await fb.importEntityData(importData)
          return resp
        }

        case EntityTypes.Zones: {
          const resp = await fb.importEntityData(importData)
          return resp
        }

        case EntityTypes.VendorServices: {
          const resp = await fb.importEntityData(importData)
          return resp
        }

        case EntityTypes.Consignments: {
         // Convert number props to numeric.
         importData.data.forEach((el) => {
          convertConsignmentImportDataToNumeric(el)
         })
          const resp = await fb.importEntityData(importData)
          return resp
        }

        default:
          return {
            result: {
              isSuccess: false,
              errorMessage: 'Entity is not supported.',
            },
          }
      }
    } catch (error) {
      notification.warning({
        message: error?.code ?? 'Error',
        description: error?.message ?? 'Please try again later.',
      })
      return {
        result: {
          isSuccess: false,
          errorMessage: error?.message ?? 'Some Error Occurred.',
        },
      }
    }
  },

  async [ToolsAction.DOWNLOAD_ENTITY_SAMPLE](context, entity) {
    try {
      const url = await fb.downloadEntitySample(entity)
      return url
    } catch (error) {
      notification.warning({
        message: error?.code ?? 'Error',
        description: error?.message ?? 'Please try again later.',
      })
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
