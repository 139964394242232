import * as fb from '../..'
import { CustomerFormData } from '../../../../types/appContracts/CustomerFormData'
import { FirebaseCollections } from '../../../../types/enums/FirebaseCollections'
import { FirebaseFunctions } from '../../../../types/enums/FirebaseFunctions'
import { IAppDocument } from '../../../../types/interfaces/IAppDocument'
import { CustomersDocument } from '../../../../types/firebaseCollectionContracts/CustomersDocument'
import { CustomersSearchDocument } from '../../../../types/firebaseCollectionContracts/CustomersSearchDocument'
import * as common from '../../common'
import chunk from 'lodash/chunk'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { CustomerBooksDocument } from '@/types/firebaseCollectionContracts/CustomerBooksDocument'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { firebaseStoragePaths } from '../../../helpers/constants/index'
import { formatStringParam } from '@/services/helpers'
import { RefIssueFormData } from '@/types/appcontracts/RefIssueFormData'

export async function setupCustomersSearchListener(
  callbackOnSuccess: (customersSearch: IAppDocument<CustomersSearchDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.SearchAggregates)
      .doc(FirebaseCollections.Customers)
      .onSnapshot(
        (querySnapshot) => {
          const customersSearch: IAppDocument<CustomersSearchDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as CustomersSearchDocument,
          }

          callbackOnSuccess(customersSearch)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

// UpsertCustomer ?? => One single method for CRUD ops ?
export async function setOrDeleteCustomer(data: DocumentOperation<CustomerFormData>) {
  data.messageType = FirebaseCollections.Customers
  const resp = await common.callFunction<ICustomersSearchMapping>(
    FirebaseFunctions.SetOrDeleteEntity,
    data,
  )
  return resp
}

export async function setOrDeleteRefIssue(data: DocumentOperation<RefIssueFormData>) {
  data.messageType = FirebaseCollections.RefIssues
  const resp = await common.callFunction<void>(FirebaseFunctions.SetOrDeleteEntity, data)
  return resp
}

/**
 * Fetch Customers from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchCustomersFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
) {
  // Put a limit on nextSize
  // Try to fetch some amount from server on each trigger, may be 10 ?
  // Since we are getting latest 50 on load, when more docs are required we need to fetch from old records
  // ISSUE: We first fetch 500 docs, now we import more 500 docs. THen we fetch latest 50.
  // So the problem is now we have latest 50 and oldest 500. No way fetch records in b/w.

  // fetch from server based on lastModified
  // TODO: MERGE below result with existing list
  // TODO: Call whenever cust are added/imported

  // Caution: It picks from cache also at times.
  // Case: All docs from firestore emulator was deleted but it was still fetching 15docs
  // fetchSize = fetchSize > 300 ? 300 : fetchSize
  // console.log("Rcvd lastDocRef: ", lastDocRef)
  const customersMap = new Map<string, IAppDocument<CustomersDocument>>()
  const fieldPath = 'audit.modifiedOn'

  const query = fb.db
    .collection('customers')
    .orderBy(fieldPath, 'desc')
    .limit(fetchSize)

  const serverDocs = lastDocRef
    ? await query.startAfter(lastDocRef).get({ source: 'server' })
    : await query.get({ source: 'server' })

  serverDocs.forEach((doc) =>
    customersMap.set(doc.id, { id: doc.id, doc: doc.data() as CustomersDocument }),
  )
  // console.log('fetchCustomersFromServer: Server Size - ', serverDocs.docs?.length)

  if (!serverDocs.empty) {
    lastDocRef = serverDocs.docs[serverDocs.docs.length - 1]
  }
  const resp = { customersMap, lastDocRef }
  return resp
}

export async function fetchCustomersByDocIdList(idList: Array<string>) {
  const chunks = chunk(idList, 10)
  const customersMap = new Map<string, IAppDocument<CustomersDocument>>()

  const promises = chunks.map((arr) => {
    return fb.db
      .collection(FirebaseCollections.Customers)
      .where(fb.dbFieldPath.documentId(), 'in', arr)
      .get()
  })
  const resp = await Promise.all(promises)
  resp.map((rec) => {
    rec.forEach((doc) =>
      customersMap.set(doc.id, { id: doc.id, doc: doc.data() as CustomersDocument }),
    )
  })

  return customersMap
}

export async function fetchCustomerProfile(id: string) {
  // console.log('ID: ', id)
  let promises = []

  promises.push(
    fb.db
      .collection(FirebaseCollections.Customers)
      .doc(id)
      .get(),
    fb.db
      .collection(FirebaseCollections.CustomerBooks)
      .doc(id)
      .get(),
  )

  const resp = await Promise.all(promises)
  // console.log('Prof Resp: ', resp)
  const customersDoc = resp[0].data() as CustomersDocument
  const customerBooksDoc = resp[1].data() as CustomerBooksDocument

  return { customersDoc, customerBooksDoc }
}

export async function uploadCustomerProfilePhoto(file: File, id: string): Promise<string | null> {
  if (id) {
    const path = formatStringParam(firebaseStoragePaths.customerProfilePhoto, { id: id })
    const url = await common.uploadToStorageAndGetLink(file, path)
    return url
  }
  return null
}

export async function updateCustomerPhotoUrl(url: string | null, id: string) {
  // URL can be null to remove pic.
  if (id) {
    const docRef = fb.db.collection(FirebaseCollections.Customers).doc(id)
    const modifiedByData = common.getModifiedByData()
    await docRef.update({
      photoUrl: url ?? null,
      ...modifiedByData,
    })
  }
}
