<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-avatar shape="square" size="large" :class="$style.avatar" :src="photoUrl">
        <template #icon><UserOutlined /></template>
      </a-avatar>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <div>
            <strong>{{ $t('topBar.profileMenu.hello') }}, {{ user.name || 'Anonymous' }}</strong>
          </div>
          <div>
            <strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
            {{ user.role?.toUpperCase() || '—' }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <div>
            <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
            {{ user.email || '—' }}
          </div>
          <div>
            <strong class="mr-1">{{ $t('topBar.profileMenu.phone') }}:</strong>
            —
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <!-- <a href="javascript: void(0);"> </a> -->
          <router-link :to="{ name: 'userProfile' }" replace>
            <i class="fe fe-user mr-2" /> {{ $t('topBar.profileMenu.editProfile') }}
          </router-link>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <a href="javascript: void(0);" @click="logout">
            <i class="fe fe-log-out mr-2" />
            {{ $t('topBar.profileMenu.logout') }}
          </a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { computed, defineComponent } from 'vue'
import * as actions from '../../../store/actions.type'

export default defineComponent({
  components: {
    UserOutlined,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    const photoUrl = computed(() => user.value.photoUrl)

    const logout = () => {
      store.dispatch(`user/${actions.UsersAction.LOGOUT_USER_ACTION}`)
    }

    return {
      user,
      logout,
      photoUrl,
    }
  },
})
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
