export interface AppUser {
  id: string
  name: string
  role: string
  email: string
  authorized: boolean
  photoUrl: string
}

export class FirebaseUser {
  appUser: AppUser

  constructor() {
    this.appUser = {
      id: '',
      name: '',
      email: '',
      role: '',
      authorized: false,
      photoUrl: '',
    }
  }

  /**
   * If firebase user exists, get role and setup appUser
   * @param user firebase user
   */
  async SetupAndVerifyUser(user: firebase.default.User | null) {
    if (user) {
      const claims = await this.GetUserClaims(user)

      // Based on below condition user is either allowed or denied access.
      const isAuthorized =
        claims.role != null && claims.role != undefined && user.emailVerified ? true : false

      this.appUser.id = user?.uid ?? ''
      this.appUser.name = user?.displayName ?? ''
      this.appUser.email = user?.email ?? ''
      this.appUser.role = claims.role ?? ''
      this.appUser.photoUrl = user?.photoURL ?? ''
      this.appUser.authorized = isAuthorized
    }
  }

  async IsRoleAssigned(user: firebase.default.User | null) {
    if (user) {
      const claims = await this.GetUserClaims(user)
      const isAuthorized = claims.role != null && claims.role != undefined ? true : false
      return isAuthorized
    }
    return false
  }

  async GetUserClaims(user: firebase.default.User) {
    const token = await user.getIdTokenResult()
    return token.claims
  }
}
