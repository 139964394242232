import * as fb from '../../../services/firebase/modules/companies'
import { notifySuccess, notifyWarning } from '../../helpers'
import { CompaniesAction } from '../../actions.type'
import { CompaniesMutation } from '../../mutations.type'

const state = () => ({
  companiesIdMap: new Map(),
  companiesLoading: true,
  companiesSearchList: [],
  fetchSize: 15,
  lastDocRef: null,
  unsubscribeListenersList: [],
})

const getters = {
  companiesExists: (state) => state.companiesIdMap.size > 0,
  companyList: (state) => {
    if (state.companiesIdMap) {
      return [...state.companiesIdMap.values()]
    } else {
      return []
    }
  },
  getInvoiceCounter: (state) => (companyId) => {
    const data = state.companiesSearchList?.find((x) => x.id === companyId)?.invoiceCounter
    return data
  },
  getManualInv: (state) => (companyId) => {
    const data = state.companiesSearchList?.find((x) => x.id === companyId)?.manualInv || []
    return data
  },
  getManualInvoiceIdList: (state) => {
    const allManuals = state.companiesSearchList?.map((x) => x.manualInv)
    const mergd = [].concat.apply([], allManuals)
    return mergd
  },
  getAutoInvoiceIdList: (state) => {
    const invIds = []
    state.companiesSearchList?.forEach((x) => {
      x.invoiceCounter?.forEach((y) => {
        const yy = y.year % 100
        const month = y.month + 1
        const mm = month.toString().padStart(2, '0')
        const pfx = `INV${y.prefix}${yy}${mm}`
        y.numbers?.forEach((z) => {
          const nn = z.toString().padStart(4, '0')
          invIds.push(`${pfx}${nn}`)
        })
      })
    })
    return invIds
  },
}

const mutations = {
  [CompaniesMutation.SET_COMPANIES_LOADING](state, payload) {
    state.companiesLoading = payload
  },

  [CompaniesMutation.SET_COMPANIES_MAP](state, payload) {
    // Merge Maps. Last one takes priority over keys.
    state.companiesIdMap = payload ? new Map([...state.companiesIdMap, ...payload]) : new Map()
  },

  [CompaniesMutation.SET_COMPANIES_SEARCH_LIST](state, payload) {
    state.companiesSearchList = payload || []
  },

  [CompaniesMutation.SET_COMPANIES_LAST_DOCREF](state, payload) {
    state.lastDocRef = payload
  },

  [CompaniesMutation.SET_COMPANIES_UNSUBSCRIBE_LISTENERS](state, payload) {
    state.unsubscribeListenersList.push(payload)
  },
}

const actions = {
  async [CompaniesAction.SET_OR_DELETE_COMPANY](context, payload) {
    try {
      // TODO: Remove company from map on DELETE.
      const resp = await fb.setOrDeleteCompany(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Company ${payload?.action?.toLowerCase()} successful`)
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  async [CompaniesAction.ADD_NEW_COMPANY](context, payload) {
    // TODO: Check payload if op is create
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteCompany(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Company added successfully`)
        // await context.dispatch(CompaniesAction.FETCH_COMPANIES_BY_IDLIST, {
        //   idList: [resp.responseData?.id],
        // })
        return true
      }
      return false
    } catch (error) {
      notifyWarning(error)
      return false
    }
  },

  async [CompaniesAction.UPDATE_COMPANY](context, payload) {
    // TODO: Use payload data for notification
    try {
      const resp = await fb.setOrDeleteCompany(payload)
      if (resp.result.isSuccess) {
        notifySuccess(`Updated Company`, `Refresh to see data`)
      }
    } catch (error) {
      notifyWarning(error)
    }
  },

  async [CompaniesAction.FETCH_COMPANIES_FROM_SERVER](context, payload) {
    try {
      const resp = await fb.fetchCompaniesFromServer(payload.fetchSize, context.state.lastDocRef)
      context.commit(CompaniesMutation.SET_COMPANIES_MAP, resp.data)
      context.commit(CompaniesMutation.SET_COMPANIES_LAST_DOCREF, resp.lastDoc)
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [CompaniesAction.FETCH_COMPANIES_ON_LOAD](context) {
    try {
      context.commit(CompaniesMutation.SET_COMPANIES_LOADING, true)

      // Making companies map null on refresh, otherwise latest wont show up on top.
      context.commit(CompaniesMutation.SET_COMPANIES_LAST_DOCREF, null)
      context.commit(CompaniesMutation.SET_COMPANIES_MAP, null)

      await context.dispatch(CompaniesAction.FETCH_COMPANIES_FROM_SERVER, {
        fetchSize: context.state.fetchSize,
      })
    } catch (error) {
      notifyWarning(error, true)
    } finally {
      context.commit(CompaniesMutation.SET_COMPANIES_LOADING, false)
    }
  },

  async [CompaniesAction.FETCH_COMPANIES_BY_ID](context, payload) {
    try {
      const docId = payload?.docId
      if (docId) {
        const customersMapRes = await fb.fetchCompanyByDocId(docId)
        context.commit(CompaniesMutation.SET_COMPANIES_MAP, customersMapRes)
        return customersMapRes.get(docId)
      } else {
        return null
      }
    } catch (error) {
      notifyWarning(error, true)
    }
  },

  async [CompaniesAction.LOAD_COMPANIES_SEARCH_BY_LISTENERS](context) {
    try {
      const unsubscribe = await fb.setupCompaniesListener(
        (companiesSearchDoc) => {
          context.commit(CompaniesMutation.SET_COMPANIES_LOADING, true)
          context.commit(
            CompaniesMutation.SET_COMPANIES_SEARCH_LIST,
            companiesSearchDoc.doc?.companyMapping,
          )
          context.commit(CompaniesMutation.SET_COMPANIES_LOADING, false)
        },
        () => {
          context.commit(CompaniesMutation.SET_COMPANIES_LOADING, false)
        },
      )
      context.commit(CompaniesMutation.SET_COMPANIES_UNSUBSCRIBE_LISTENERS, unsubscribe)
    } catch (error) {
      // TODO: Handle Error.
      notifyWarning(error, true)
    }
  },

  async [CompaniesAction.UNSUBSCRIBE_COMPANIES_LISTENERS](context) {
    try {
      // Unsubscribe all the listeners.
      context.state.unsubscribeListenersList.forEach((unsubscribe) => unsubscribe())
    } catch (error) {
      console.log(error)
      notifyWarning(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
