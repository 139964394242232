import * as fb from '../..'
import * as common from '../../common'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { VendorFormData } from '@/types/appcontracts/VendorFormData'
import { VendorSetResponse } from '@/types/appcontracts/VendorSetResponse'
import { FirebaseFunctions } from '@/types/enums/FirebaseFunctions'
import { FirebaseCollections } from '@/types/enums/FirebaseCollections'
import { VendorsDocument } from '@/types/firebaseCollectionContracts/VendorsDocument'
import { firebaseQueryFieldPaths } from '@/services/helpers/constants'
import { VendorsSearchDocument } from '@/types/firebaseCollectionContracts/VendorsSearchDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { VendorServiceFormData } from '@/types/appcontracts/VendorServiceFormData'
import { VendorServicesDocument } from '@/types/firebaseCollectionContracts/VendorServicesDocument'

export async function setupVendorsAliasListener(
  callbackOnSuccess: (vendorsSearchDoc: IAppDocument<VendorsSearchDocument>) => void,
  callbackOnError: () => void,
) {
  try {
    const unsubscribe = fb.db
      .collection(FirebaseCollections.SearchAggregates)
      .doc(FirebaseCollections.Vendors)
      .onSnapshot(
        (querySnapshot) => {
          const vendorsSearchDoc: IAppDocument<VendorsSearchDocument> = {
            id: querySnapshot.id,
            doc: querySnapshot.data() as VendorsSearchDocument,
          }

          callbackOnSuccess(vendorsSearchDoc)
        },
        () => {
          callbackOnError()
        },
      )
    return unsubscribe
  } catch (error) {
    return false
  }
}

export async function setOrDeleteVendor(data: DocumentOperation<VendorFormData>) {
  data.messageType = FirebaseCollections.Vendors
  const resp = await common.callFunction<VendorSetResponse>(
    FirebaseFunctions.SetOrDeleteEntity,
    data,
  )
  return resp
}

export async function setOrDeleteVendorService(data: DocumentOperation<VendorServiceFormData>) {
  data.messageType = FirebaseCollections.VendorServices
  const resp = await common.callFunction<string>(FirebaseFunctions.SetOrDeleteEntity, data)
  return resp
}

/**
 * Fetch Vendors from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchVendorsFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<VendorsDocument>(
    FirebaseCollections.Vendors,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
  )

  return resp
}

/**
 * Fetch VendorServices from firestore server with a limit of fetchSize. If oldestModifed is specified it fetch after that date.
 * @param {number} fetchSize
 * @param {null | firebase.default.firestore.QueryDocumentSnapshot} lastDocRef
 * @returns
 */
export async function fetchVendorServicesFromServer(
  fetchSize: number,
  lastDocRef: null | firebase.default.firestore.QueryDocumentSnapshot,
) {
  const resp = await common.fetchDocumentsFromServerByOrder<VendorServicesDocument>(
    FirebaseCollections.VendorServices,
    firebaseQueryFieldPaths.ModifiedOn,
    fetchSize,
    lastDocRef,
  )

  return resp
}

export async function fetchVendorServiceById(docId: string) {
  if (!docId) {
    return null
  }
  const mapData = new Map<string, IAppDocument<VendorServicesDocument>>()
  const resp = await fb.db
    .collection(FirebaseCollections.VendorServices)
    .doc(docId)
    .get()

  mapData.set(docId, { id: docId, doc: resp.data() as VendorServicesDocument })
  return mapData
}

export async function fetchVendorProfile(id: string) {
  // console.log('ID: ', id)

  const docData = (
    await fb.db
      .collection(FirebaseCollections.Vendors)
      .doc(id)
      .get()
  ).data() as VendorsDocument

  return docData
}
