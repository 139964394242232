export const firebaseStoragePaths = {
  customerProfilePhoto: 'customers/:id/photo/profilePhoto',
  userProfilePhoto: 'appUsers/:id/photo/profilePhoto',
}

export const firebaseQueryFieldPaths = {
  ModifiedOn: 'audit.modifiedOn',
}

export const dateFormats = {
  momentDisplayFormat: 'Do MMM YYYY',
  momentValueFormat: 'YYYY-MM-DDT00:00:00Z',
}

export const gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
