export const getMenuData = [
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/power/dashboard',
    icon: 'fe fe-home',
  },
  {
    category: true,
    title: 'Sales/Purchase',
    key: 'dihec',
  },
  {
    title: 'Sales',
    key: 'e2es3b',
    url: '#',
    icon: 'fe fe-trending-up',
    children: [
      {
        title: 'Customers',
        key: 'th3exd',
        url: '/sales/customers',
        subpaths: [
          {
            title: 'New',
            key: 'newCustomer',
            url: '/sales/customers/new',
          },
          {
            title: 'Profile',
            key: 'customerProfile',
            params: true,
            url: '/sales/customers/:id/profile',
            subpaths: [
              {
                title: 'Edit',
                key: 'editCustomer',
                params: true,
                url: '/sales/customers/:id/edit',
              },
            ],
          },
        ],
      },
      {
        title: 'Consignments',
        key: '2xezb5',
        url: '/sales/consignments',
      },
      {
        title: 'Invoices',
        key: 'o4vudh',
        url: '/sales/invoices',
      },
      {
        title: 'Credit Notes',
        key: '33ppt',
        url: '/sales/creditnotes',
        disabled: true,
      },
      {
        title: 'Payments Received',
        key: '6ttzld',
        url: '/sales/paymentsreceived',
        disabled: true,
      },
      {
        title: 'Estimates',
        key: 'b441j5',
        url: '/sales/estimates',
        disabled: true,
      },
    ],
  },
  {
    title: 'Purchase',
    key: 'pie98',
    url: '#',
    icon: 'fe fe-shopping-cart',
    children: [
      {
        title: 'Vendors',
        key: 'z7no7r',
        url: '/purchase/vendors',
        subpaths: [
          {
            title: 'New',
            key: 'newVendor',
            url: '/purchase/vendors/new',
          },
          {
            title: 'Profile',
            key: 'vendorProfile',
            url: '/purchase/vendors/profile',
          },
        ],
      },
      {
        title: 'Add Ons',
        key: '2mcekq',
        url: '/purchase/addons',
      },
      {
        title: 'Expenses',
        key: 'k6d9po',
        url: '/purchase/expenses',
        disabled: true,
      },
      {
        title: 'Bills',
        key: 'nn42gq',
        url: '/purchase/bills',
        disabled: true,
      },
      {
        title: 'Vendor Credits',
        key: '2whrj',
        url: '/purchase/vendorcredits',
        disabled: true,
      },
    ],
  },
  {
    title: 'Shipping',
    key: '4dt10p',
    url: '#',
    icon: 'fe fe-map-pin',
    children: [
      {
        title: 'Zones',
        key: 'jgjto6',
        url: '/shipping/zones',
      },
      {
        title: 'Rates',
        key: 'nhwc8s',
        url: '/shipping/rates',
      },
      {
        title: 'Track',
        key: 'nhwc8q',
        url: '/shipping/track',
      },
      {
        title: 'Locations',
        key: '2xw87p',
        url: '/shipping/locations',
        disabled: true,
      },
    ],
  },
  {
    category: true,
    title: 'Management',
    key: 'iej0h9',
    roles: 'owner,executive',
  },
  {
    title: 'Management',
    key: '76e6os',
    url: '#',
    icon: 'fe fe-cpu',
    roles: 'owner,executive',
    children: [
      {
        title: 'Companies',
        key: 'fr0gss',
        url: '/management/companies',
      },
      {
        title: 'Taxes',
        key: 'sdkj0n',
        url: '/management/taxes',
      },
      {
        title: 'Users & Roles',
        key: '309vm6',
        url: '/management/usersroles',
      },
      {
        title: 'Employees',
        key: '4u7is',
        url: '/management/employees',
        disabled: true,
      },
    ],
  },
  {
    category: true,
    title: 'Tools',
    key: 'kdidhj',
  },
  {
    title: 'Import/Export',
    key: 'li60a',
    url: '/tools/importexport',
    icon: 'fe fe-cloud-drizzle',
  },
  {
    title: 'Reports',
    key: '208ntr',
    url: '/tools/reports',
    icon: 'fe fe-activity',
    disabled: true,
  },
  {
    category: true,
    title: 'Settings',
    key: '1tdyti',
  },
  // {
  //   title: 'Preferences',
  //   key: 'li70a',
  //   url: '/settings/preferences',
  //   icon: 'fe fe-cloud-settings',
  // },
]
